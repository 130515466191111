import React, {useEffect, useState} from 'react';

import './Footer.css';
import {
    Button,
    FormControl,
    FormControlLabel,
    InputBase,
    makeStyles,
    Radio,
    RadioGroup,
    Typography
} from '@material-ui/core';

import {useHistory} from 'react-router-dom';
// import {
//     FaFacebook,
//     FaInstagram,
//     FaYoutube,
//     FaTwitter,
//     FaLinkedin
// } from 'react-icons/fa';
import {withTranslation} from 'react-i18next';
import yesbitComWhiteLogo from '../../images/home/yesbitComWhiteLogo.png';


import {referralActions} from "../../redux/actions/referralActions";
import {useDispatch} from "react-redux";

import rIcon from '../../images/home/rIcon.png';
import tIcon from '../../images/home/tIcon.png';
import iIcon from '../../images/home/iIcon.png';
import MIcon from '../../images/home/MIcon.png';


import subscribeBG from '../../images/home/subscribeBG.png';

import caFlag from '../../images/caFlag.png';
import footerAddressIcon from '../../images/home/footerAddressIcon.png';
import footerWhatsAppIcon from '../../images/home/footerWhatsAppIcon.png';
import footerEmailIcon from '../../images/home/footerEmailIcon.png';
import {checkEmail} from "../../utils/Checker"
import {motion} from "framer-motion"
import config from '../../config/config.json'

function Footer({ t, width }) {
    const [phone, setPhone] = useState(width <= 745);
    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        setPhone(width <= 745)
    }, [width]);
    const useStyles = makeStyles({
        button: {
            minWidth: 0, color: 'white', fontSize: phone ? 14 : 14, padding: 0, textTransform: 'none', fontFamily: "Avenir", fontWeight: '500', marginTop: phone ? 8 : "unset"


        },
        socialButton: {
            minWidth: 0, minHeight: 0, alignItems: 'center', justifyContent: 'ceter', display: 'flex'
        },
        fontStyle: {
            fontFamily: "Avenir", fontWeight: '500', color: 'white', fontSize: 14
        }
    })
    const [values, setValues] = useState({
        email: '',
        lung: 'EN',
    });

    const [submitDisable, setSubmitDisable] = React.useState(true);
    const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
        setSubmitDisable(!checkEmail(event.target.value))
    };
    const [selectedValue, setSelectedValue] = React.useState('EN');

    const handleChangeLung = (value) => {
        setSelectedValue(value);
        setValues({ ...values, ["lung"]: value });
    };

    const handleClickSubmit = () => {
        dispatch(referralActions.sendSubscribe(t, values));
        setSubmitDisable(true)
    };

    const scaleButton = (onClick, title, isOpenNewWindow) => {
        return (
            <motion.div initial={{ opacity: 0.7 }} whileHover={{ scale: 1.2, opacity: 1 }} style={{ height: 48, alignItems: 'center', justifyContent: 'flex-start', display: 'flex' }}>

                {
                    isOpenNewWindow ?

                        <Button onClick={() => window.open(onClick)} className={classes.button}>{title}</Button>

                        :
                        <Button onClick={() => {
                            config.redirect && window.location.replace(`https://trade.yesbit.io${onClick}`)
                            window.scrollTo({
                                top: 0,
                                behavior: 'smooth'

                            })
                        }}
                            className={classes.button}>{title}</Button>

                }

            </motion.div >

        )
    }
    // dispatch(authActions.sendVerificationCode(values.username));

    const classes = useStyles();

    return phone ?
        (<div style={{ backgroundColor: '#050618' }}>


            <div style={{ wdith: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <div style={{ width: '91%', backgroundColor: '#3254FA', marginTop: 24, borderRadius: 20, display: 'flex', flexDirection: 'column' }}>
                    <div style={{ marginLeft: 20, marginTop: 20, justifyContent: 'center', display: 'flex', flexDirection: 'column' }}>
                        <Typography style={{ fontSize: 16, color: 'white' }} className={classes.fontStyle}>
                            {t('home_stayUpdated')}
                        </Typography>

                        <Typography style={{ fontSize: 12, color: 'white' }} className={classes.fontStyle}>
                            {t('home_stayUpdatedSub')}
                        </Typography>
                    </div>

                    <div style={{ marginLeft: 20, marginRight: 20, justifyContent: 'center', display: 'flex', flexDirection: 'column', marginTop: 24 }}>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                            <div style={{ height: 40, width: '78%', backgroundColor: '#5E79FF', borderRadius: 16 }}>
                                <div style={{ width: '80%', backgroundColor: '#5E79FF', borderRadius: 16, marginLeft: 16 }}>
                                    <InputBase
                                        style={
                                            { width: '100%', height: 40, fontSize: 14, color: 'white' }

                                        }
                                        value={values.username}
                                        onChange={handleChange('email')}
                                        placeholder={t('placeHolderLoginEmail')}

                                    />
                                </div>
                            </div>

                            <Button onClick={handleClickSubmit} disabled={submitDisable} style={{ opacity: submitDisable ? 0.1 : 1, height: 40, width: '15%', backgroundColor: 'white', borderRadius: 16, textTransform: 'none' }}>
                                <Typography style={{ fontSize: 14, color: 'black' }} className={classes.fontStyle}>{t('home_stayUpdatedSubmit')}</Typography>
                            </Button>

                        </div>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', marginTop: 8, marginBottom: 20 }}>
                            <Typography className={classes.fontStyle} style={{ fontSize: 12, marginRight: 16, color: 'white' }}>{t('home_stayUpdatedPreferredLang')}</Typography>

                            <FormControl>
                                <RadioGroup
                                    row
                                >

                                    <FormControlLabel
                                        onChange={() => {
                                            handleChangeLung("CN")
                                        }}
                                        checked={selectedValue === 'CN'} value="CN" control={<Radio color="default" />} label={<Typography style={{ color: 'white', fontSize: 12 }} className={classes.fontStyle}>中文</Typography>} />
                                    <FormControlLabel
                                        onChange={() => {
                                            handleChangeLung("EN")
                                        }}
                                        checked={selectedValue === 'EN'} value="EN" control={<Radio color="default" />} label={<Typography style={{ color: 'white', fontSize: 12 }} className={classes.fontStyle}>English</Typography>} />
                                </RadioGroup>
                            </FormControl>
                        </div>
                    </div>
                </div>
            </div>

            <div style={{ wdith: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <div style={{ width: '95%', flexDirection: 'column', marginTop: 40, borderRadius: 30, display: 'flex', alignItems: 'center', }}>
                    <div style={{ width: '95%', justifyContent: 'center', display: 'flex', flexDirection: 'column' }}>

                        <div>
                            <img style={{ width: 80 }} src={yesbitComWhiteLogo}></img>
                            <Typography style={{ color: '#8B96A7', fontSize: 12 }}>{t('footer_subtitle')}</Typography>

                        </div>
                    </div>

                    <div style={{ width: '95%', marginTop: 24, display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                        <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', }}>
                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                                <Typography className={classes.fontStyle} style={{ color: '#8B96A7', fontSize: 12 }}>{t("footer_col1")}</Typography>

                                <Button onClick={() => {
                                    window.scrollTo({
                                        top: 0,
                                        behavior: 'smooth'

                                    });
                                }} className={classes.button}>{t("footer_col1Sub1")}</Button>
                                <Button onClick={() => {
                                    config.redirect && window.location.replace('https://trade.yesbit.io/login')
                                }} className={classes.button}>{t("footer_col1Sub2")}</Button>
                                <Button onClick={() => {
                                    config.redirect && window.location.replace('https://trade.yesbit.io/register')
                                }} className={classes.button}>{t("footer_col1Sub3")}</Button>
                                <Button onClick={() => {
                                    config.redirect && window.location.replace('https://trade.yesbit.io/trade')
                                }} className={classes.button}>{t("footer_col1Sub4")}</Button>
                                {/* <Button className={classes.button}>{t("footer_col1Sub5")}</Button> */}
                            </div>

                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                                <Typography className={classes.fontStyle} style={{ color: '#8B96A7', fontSize: 12 }}>{t("footer_col2")}</Typography>

                                <Button onClick={() => window.open("https://hpblink.zendesk.com/hc/en-us/sections/7111220790932-Announcements")} className={classes.button}>{t("footer_col2Sub1")}</Button>
                                <Button onClick={() => window.open(localStorage.getItem('lng') === 'cn' ? "https://otcdocs.yesbit.io/v/jian-ti-zhong-wen/ " : "https://otcdocs.yesbit.io/")} className={classes.button}>{t("footer_col2Sub2")}</Button>
                                <Button onClick={() => history.push('/referral-info')} className={classes.button}>{t("footer_col2Sub3")}</Button>
                                <Button onClick={() => history.push('/nft-homepage')} className={classes.button}>{t("footer_col2Sub4")}</Button>
                                <Button onClick={() => window.open("https://otcdocs.yesbit.io/v/english/faq/cryptocurrencies")} className={classes.button}>{t("footer_col2Sub5")}</Button>
                            </div>
                        </div>
                        <div style={{ height: 1, width: '91%', backgroundColor: '#252525', marginTop: 12, marginBottom: 12 }} />

                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', width: '100%' }}>
                            <Typography className={classes.fontStyle} style={{ color: '#8B96A7', fontSize: 12, marginBottom: 12 }}>{t("footer_col3")}</Typography>

                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                <img style={{ height: 24, widht: 24, marginRight: 8 }} src={footerEmailIcon} />
                                <Typography style={{ fontSize: 14 }} className={classes.fontStyle}>{t('footer_col3Email')}: otc@yesbit.ca</Typography>
                            </div>

                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 16 }}>
                                <img style={{ height: 24, widht: 24, marginRight: 8 }} src={footerWhatsAppIcon} />
                                <div>
                                    <Typography style={{ fontSize: 14 }} className={classes.fontStyle}>{t('footer_col3WhatsApp')}: +1 (647) 947-6258</Typography>
                                </div>
                            </div>

                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 16 }}>
                                <img style={{ height: 24, widht: 24, marginRight: 8 }} src={footerAddressIcon} />
                                <Typography style={{ fontSize: 14 }} className={classes.fontStyle}>{t('footer_col3Address')}: 5255 Yonge St, Suite 1220, North York, ON, Canada M2N4P6</Typography>
                            </div>
                        </div>
                    </div>

                </div>


            </div>

            <div style={{ wdith: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                <div style={{ height: 1, width: '91%', backgroundColor: '#252525', marginTop: 40, marginBottom: 40 }} />
                <div style={{ width: '91%', display: 'flex', alignItems: 'center', flexDirection: 'column', marginBottom: 40, }}>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', alignItems: 'center' }} >
                        <Typography className={classes.fontStyle} style={{ marginRight: 16 }}>
                            yesbit © 2023
                        </Typography>
                        <div style={{ display: 'flex' }}>
                            <Button onClick={() => window.open("https://twitter.com/yesbit_canada")} className={classes.socialButton}>
                                <img style={{ height: 20, widht: 20 }} src={tIcon}></img>
                            </Button>

                            <Button onClick={() => window.open(localStorage.getItem('lng') === 'cn' ? " https://medium.com/@YesbitOTC_CN" : "https://medium.com/@YesbitOTC")} className={classes.socialButton}>
                                <img style={{ height: 20, widht: 20, }} src={MIcon}></img>
                            </Button>

                            <Button onClick={() => window.open("https://www.instagram.com/yesbitotc/")} className={classes.socialButton}>
                                <img style={{ height: 20, widht: 20, }} src={iIcon}></img>
                            </Button>

                            <Button onClick={() => window.open("https://www.reddit.com/r/YesbitOTC/")} className={classes.socialButton}>
                                <img style={{ height: 20, widht: 20, }} src={rIcon}></img>
                            </Button>
                        </div>


                    </div>
                    <div onClick={() => { window.open("https://www10.fintrac-canafe.gc.ca/msb-esm/public/detailed-information/msb-details/7b226d73624f72674e756d626572223a3136373031342c227072696d617279536561726368223a7b226f72674e616d65223a22687062222c2273656172636854797065223a337d7d/") }} style={{ display: 'flex', flexDirection: 'row', backgroundColor: '#0F1241', width: '100%', alignItems: 'center', marginTop: 16, borderRadius: 8 }} >
                        <img style={{ height: 28, marginLeft: 8 }} src={caFlag}></img>

                        <Typography className={classes.fontStyle} style={{ marginLeft: 8, color: 'white', fontSize: 12 }}>
                            {t("MSB")} FINTRAC #M22187471
                        </Typography>
                    </div>
                </div>
            </div>
        </div>)
        :
        (

            <div style={{ backgroundColor: '#050618' }}>
                {/* <div className="footer_seperateLine"></div> */}
                {/* <div className='footer-links'>
                <div className='footer-link-wrapper'>
                    <div className='footer-items-about' >
                        <h2>{t("footer_aboutUsTitle")}</h2>
                        <p>
                            {t("footer_aboutUsContent")}
                        </p>
                        <span style={{ fontWeight: 'bold' }}> {'MSB: '}
                            <a target='_blank' href='https://www10.fintrac-canafe.gc.ca/msb-esm/public/detailed-information/msb-details/7b226d73624f72674e756d626572223a3136373031342c227072696d617279536561726368223a7b226f72674e616d65223a22687062222c2273656172636854797065223a337d7d/' style={{ fontWeight: 'initial', textDecoration: 'underline' }}>
                                FINTRAC #M22187471
                            </a>
                        </span>
                        <Button onClick={() => { window.open("https://otcdocs.yesbit.io/v/english/faq/cryptocurrencies") }} style={{ marginRight: 8, borderRadius: 8,backgroundColor:'#f9f9f9',marginTop:24, textTransform: 'none' }}>
                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', marginLeft: 16, marginRight: 16 }}>
                                <Typography style={{ fontSize: phone ? 10 : 14, fontWeight: 600, color: '#1C2237' }}>{t('cryptoCurrencies')}</Typography>
                            </div>
                        </Button>
                    </div>
                    <div className='footer-link-items'>
                        <h2>{t('footer_contact')}</h2>
                        <p>{t("footer_email")} otc@yesbit.ca</p>
                        <p>{t("whatsapp")} <div style={{display:"inline-grid"}}><div>{"+1 (647) 947-6258"}</div><div>{"+1 (437) 985-1888"}</div></div></p>

                    </div>
                    <div className='footer-link-items'>
                        <h2>{t('footer_otherPlatform')}</h2>

                        <a target="_blank" rel="noopener noreferrer" href="https://twitter.com/yesbit_canada">Twitter</a>
                        <a target="_blank" rel="noopener noreferrer" href={t("mediumLink")}>Medium</a>
                        <a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/yesbitotc/">Instagram</a>
                        <a target="_blank" rel="noopener noreferrer" href="https://www.reddit.com/r/YesbitOTC/">Reddit</a>

                        <h2>{t('officeAddress')}</h2>
                        <p style={{ marginTop: 0 }}>5255 Yonge St, Suite 1220, North York, ON, Canada M2N4P6</p>
                    </div>
                </div>

            </div> */}
                {/* <section className='social-media'>
                <div className='social-media-wrap'>
                    <div className='footer-logo'>
                        <Link to='/' className='social-logo'>
                            <img alt="" src={navLogo} className='navbar-icon' />

                        </Link>


                    </div>
                    <div >

                        <small className='website-rights'>OTC yesbit © 2020</small>
                    </div>

                </div>
            </section> */}

                <div style={{ wdith: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <div style={{ width: '81%', overflow: 'hidden', backgroundColor: '#3254FA', marginTop: 64, borderRadius: 30, display: 'flex', justifyContent: 'space-between' }}>
                        <div style={{ width: '60%' }}>
                            <div style={{ width: '100%', justifyContent: 'center', display: 'flex', flexDirection: 'column', paddingLeft: 80, marginTop: 40 }}>
                                <Typography style={{ fontSize: 40, color: 'white' }} className={classes.fontStyle}>
                                    {t('home_stayUpdated')}
                                </Typography>

                                <Typography style={{ fontSize: 16, color: 'white' }} className={classes.fontStyle}>
                                    {t('home_stayUpdatedSub')}
                                </Typography>
                            </div>

                            <div style={{ width: '100%', justifyContent: 'center', display: 'flex', flexDirection: 'column', paddingLeft: 80, paddingRight: 40, marginTop: 40, marginBottom: 32 }}>
                                <div style={{ display: 'flex', flexDirection: 'row', }}>
                                    <div style={{ height: 60, width: '60%', borderRadius: 24 }}>
                                        <InputBase
                                            style={
                                                { borderTopLeftRadius: 24, borderBottomLeftRadius: 24,color:'#050618', backgroundColor: 'white', width: '100%', height: '60px', fontSize: 20, padding: 20 }
                                            }
                                            value={values.username}
                                            onChange={handleChange('email')}
                                            placeholder={t('placeHolderLoginEmail')}

                                        />
                                    </div>

                                    <Button onClick={handleClickSubmit} disabled={submitDisable} style={{ opacity: submitDisable ? 0.5 : 1, height: 60, width: '16%', backgroundColor: '#151A77',borderTopLeftRadius:0,borderBottomLeftRadius:0, borderBottomRightRadius: 20,borderTopRightRadius:20, textTransform: 'none' }}>
                                        <Typography style={{ fontSize: 16, color: 'white' }} className={classes.fontStyle}>{t('home_stayUpdatedSubmit')}</Typography>
                                    </Button>

                                </div>
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', marginTop: 8 }}>
                                    <Typography className={classes.fontStyle} style={{ fontSize: 16, marginRight: 16, color: 'white' }}>{t('home_stayUpdatedPreferredLang')}</Typography>

                                    <FormControl>
                                        <RadioGroup
                                            row
                                        >
                                            <FormControlLabel
                                                onChange={() => {
                                                    handleChangeLung("CN")
                                                }}
                                                checked={selectedValue === 'CN'} value="CN" control={<Radio color="default" />} label={<Typography style={{ color: 'white' }} className={classes.fontStyle}>中文</Typography>} />
                                            <FormControlLabel
                                                onChange={() => {
                                                    handleChangeLung("EN")
                                                }}
                                                checked={selectedValue === 'EN'} value="EN" control={<Radio color="default" />} label={<Typography style={{ color: 'white' }} className={classes.fontStyle}>English</Typography>} />
                                        </RadioGroup>
                                    </FormControl>
                                </div>
                            </div>
                        </div>
                        <img style={{ width: 525 * 1, height: 308 * 1 }} src={subscribeBG}></img>
                    </div>
                </div>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: 100 }}>
                    <div style={{ width: '30%', justifyContent: 'center', alignItems: 'center', display: 'flex', flexDirection: 'column', border: "1.6px solid #213555", paddingLeft: 24, paddingRight: 24, paddingTop: 20, paddingBottom: 20, borderRadius: 20 }}>


                        <img style={{ width: "30%", marginBottom: 16 }} src={yesbitComWhiteLogo}></img>
                        <Typography style={{ color: '#8B96A7', fontSize: 14, textAlign: 'center' }}>{t('footer_subtitle')}</Typography>

                    </div>
                </div>
                <div style={{ wdith: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <div style={{ width: '81%', height: 260, justifyContent: 'space-between', marginTop: "4%", borderRadius: 30, display: 'flex' }}>


                        <div style={{ width: '100%', display: 'flex', paddingLeft: 40, paddingRight: 40, justifyContent: 'space-between', flexDirection: 'row', alignItems: 'flex-start' }}>
                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                                <Typography className={classes.fontStyle} style={{ color: '#8B96A7', fontSize: 14, marginBottom: 12 }}>{t("footer_col1")}</Typography>

                                {/* onClick={() => {
                                    window.scrollTo({
                                        top: 0,
                                        behavior: 'smooth'

                                    });
                                }} */}
                                {scaleButton("/", t("footer_col1Sub1"))}

                                {scaleButton("/login", t("footer_col1Sub2"))}
                                {scaleButton("/register", t("footer_col1Sub3"))}
                                {scaleButton("/trade", t("footer_col1Sub4"))}

                            </div>

                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                                <Typography className={classes.fontStyle} style={{ color: '#8B96A7', fontSize: 14, marginBottom: 12 }}>{t("footer_col2")}</Typography>

                                {scaleButton("https://hpblink.zendesk.com/hc/en-us/sections/7111220790932-Announcements", t("footer_col2Sub1"), true)}
                                {scaleButton(localStorage.getItem('lng') === 'cn' ? "https://otcdocs.yesbit.io/v/jian-ti-zhong-wen/ " : "https://otcdocs.yesbit.io/", t("footer_col2Sub2"), true)}
                                {scaleButton("/referral-info", t("footer_col2Sub3"))}
                                {scaleButton("/nft-homepage", t("footer_col2Sub4"))}
                                {scaleButton("https://otcdocs.yesbit.io/v/english/faq/cryptocurrencies", t("footer_col2Sub5"), true)}

                                {/* <Button onClick={() => window.open("https://hpblink.zendesk.com/hc/en-us/sections/7111220790932-Announcements")} className={classes.button}>{t("footer_col2Sub1")}</Button>
                                <Button onClick={() => window.open(localStorage.getItem('lng') === 'cn' ? "https://otcdocs.yesbit.io/v/jian-ti-zhong-wen/ " : "https://otcdocs.yesbit.io/")} className={classes.button}>{t("footer_col2Sub2")}</Button>
                                <Button onClick={() => history.push('/referral-info')} className={classes.button}>{t("footer_col2Sub3")}</Button>
                                <Button onClick={() => history.push('/nft-homepage')} className={classes.button}>{t("footer_col2Sub4")}</Button>
                                <Button onClick={() => window.open("https://otcdocs.yesbit.io/v/english/faq/cryptocurrencies")} className={classes.button}>{t("footer_col2Sub5")}</Button> */}
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                                <Typography className={classes.fontStyle} style={{ color: '#8B96A7', fontSize: 14, marginBottom: 12 }}>{t("footer_col3")}</Typography>

                                <motion.div initial={{ opacity: 0.7 }} whileHover={{ scale: 1.2, opacity: 1 }} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 12 }}>
                                    <img style={{ height: 24, widht: 24, marginRight: 8 }} src={footerEmailIcon} />
                                    <Typography className={classes.fontStyle}>{t('footer_col3Email')}: otc@yesbit.ca</Typography>
                                </motion.div>

                                <motion.div initial={{ opacity: 0.7 }} whileHover={{ scale: 1.2, opacity: 1 }} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 20 }}>
                                    <img style={{ height: 24, widht: 24, marginRight: 8 }} src={footerWhatsAppIcon} />
                                    <div>
                                        <Typography className={classes.fontStyle}>{t('footer_col3WhatsApp')}: +1 (647) 947-6258</Typography>
                                        <Typography className={classes.fontStyle}></Typography>
                                    </div>
                                </motion.div>

                                <motion.div initial={{ opacity: 0.7 }} whileHover={{ scale: 1.2, opacity: 1 }} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 20 }}>
                                    <img style={{ height: 24, widht: 24, marginRight: 8 }} src={footerAddressIcon} />
                                    <Typography className={classes.fontStyle}>{t('footer_col3Address')}: 5255 Yonge St, Suite 1220, North York, ON, Canada M2N4P6</Typography>
                                </motion.div>

                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 24 }} >

                                    <motion.div initial={{ opacity: 0.7 }} whileHover={{ scale: 1.2, opacity: 1 }}>
                                        <Button onClick={() => window.open("https://twitter.com/yesbit_canada")} className={classes.socialButton}>
                                            <img style={{ height: 32, widht: 32 }} src={tIcon}></img>
                                        </Button>
                                    </motion.div>

                                    <motion.div initial={{ opacity: 0.7 }} whileHover={{ scale: 1.2, opacity: 1 }}>
                                        <Button onClick={() => window.open(localStorage.getItem('lng') === 'cn' ? " https://medium.com/@YesbitOTC_CN" : "https://medium.com/@YesbitOTC")} className={classes.socialButton}>
                                            <img style={{ height: 34, widht: 34, }} src={MIcon}></img>
                                        </Button>
                                    </motion.div>

                                    <motion.div initial={{ opacity: 0.7 }} whileHover={{ scale: 1.2, opacity: 1 }}>
                                        <Button onClick={() => window.open("https://www.instagram.com/yesbitotc/")} className={classes.socialButton}>
                                            <img style={{ height: 32, widht: 32, }} src={iIcon}></img>
                                        </Button>
                                    </motion.div>

                                    <motion.div initial={{ opacity: 0.7 }} whileHover={{ scale: 1.2, opacity: 1 }}>
                                        <Button onClick={() => window.open("https://www.reddit.com/r/YesbitOTC/")} className={classes.socialButton}>
                                            <img style={{ height: 32, widht: 32, }} src={rIcon}></img>
                                        </Button>
                                    </motion.div>

                                </div>
                            </div>
                        </div>

                    </div>


                </div>

                <div style={{ wdith: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                    <div style={{ height: 1, width: '81%', backgroundColor: '#252525', marginTop: 40, marginBottom: 40 }} />
                    <div style={{ height: 40, width: '81%', display: 'flex', alignItems: 'center', flexDirection: 'row', marginBottom: 40, justifyContent: 'space-between' }}>
                        <Typography className={classes.fontStyle} style={{ marginRight: 16 }}>
                            yesbit © 2023
                        </Typography>
                        <Button onClick={() => { window.open("https://www10.fintrac-canafe.gc.ca/msb-esm/public/detailed-information/msb-details/7b226d73624f72674e756d626572223a3136373031342c227072696d617279536561726368223a7b226f72674e616d65223a22687062222c2273656172636854797065223a337d7d/") }} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }} >
                            <img style={{ height: 32, marginRight: 8 }} src={caFlag}></img>

                            <Typography className={classes.fontStyle}>
                                {t("MSB")} FINTRAC #M22187471
                            </Typography>
                        </Button>
                    </div>
                </div>
            </div>
        );
}

export default withTranslation()(Footer);
