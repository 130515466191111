import React, { useEffect, useState, useRef } from 'react';
import { Link, NavLink } from 'react-router-dom';

import { withTranslation } from 'react-i18next';
import {
    Button,
    Typography,
    TextField,
    makeStyles,
    MenuItem,
    Avatar,
    Hidden
} from '@material-ui/core';
import CustomTextField from "../../../utils/CustomTextField";
import cad from "../../../images/coins/cad.png";
import usdt from "../../../images/coins/usdt.png";
import { useDispatch, useSelector } from "react-redux";
import { tradeActions } from "../../../redux/actions/tradeActions";
import { roundingDown } from "../../../utils/RoundingDown";
import { fundsActions } from "../../../redux/actions/fundsActions";
import { getIcons } from "../../../utils/Common";
import buyIcon from "../../../images/buyIcon.png"
import sellIcon from "../../../images/sellIcon.png"
import buyIconW from "../../../images/buyIconW.png"
import sellIconW from "../../../images/sellIconW.png"
import questionIcon from "../../../images/questionIcon.png"
import i18n from '../../../i18n';

function QuickTradeModal({ t, phone, homePage, next, payValue, setPayValue, receiveValue, setReceiveValue, coin1Selected, setCoin1Selected, coin2Selected, setCoin2Selected, selected, setSelected, showUSDT }) {
    const { tradePriceQuoteLoading, aquaBalance, livePrice, getLivePriceLoading, getAquaBalanceLoading } = useSelector(state => state.trade)
    const { userCapitals, tokensInfo } = useSelector(state => state.funds)
    const { kycDetails } = useSelector(state => state.kyc)
    const { token } = useSelector(state => state.auth);
    const [initial, setInitial] = useState(true);

    const [inputFocused1, setInputFocused1] = useState(false)
    const [inputFocused2, setInputFocused2] = useState(false)
    const amount1All = useRef(false);
    const [submitDisable, setSubmitDisable] = useState(true);
    const coinFree1 = userCapitals.find(item => item.symbol === coin1Selected);
    const [amount1Warning, setAmount1Warning] = useState(' ');
    const [selectList1, setSelectList1] = useState([]);
    const [selectList2, setSelectList2] = useState([]);
    const useStyles = makeStyles({
        paper: {
            borderRadius: phone ? 11 : 20,
            backgroundColor: '#F9F9F9',
            padding: '30px 24px',
            maxWidth: 544
        },
        div: {
            height: phone ? 35 : 65,
            borderRadius: phone ? 8 : 12,
            backgroundColor: '#F1F0F0',
            display: 'flex'
        },
        font: {
            color: submitDisable ? "#8B96A7" : 'white',
            fontFamily: 'Avenir',
            fontSize: phone ? 12 : 24,
            fontWeight: 500,
            letterSpacing: 0,
            textAlign: phone ? 'center' : 'left',
            textTransform: 'none'
        },
        button: {
            width: "100%",
            height: phone ? 49 : 70,
            borderRadius: phone ? 16 : 20,
            backgroundColor: '#162E83',
            textTransform: 'none',
            marginTop: phone ? 40 : 60
        },
        label: {
            color: '#8B96A7',
            fontFamily: 'Avenir',
            fontSize: phone ? 12 : 16,
            fontWeight: "500",
            letterSpacing: 0,
            textAlign: 'left',
            textTransform: 'none'
        },
        selected: {
            // color: 'white',
            backgroundColor: '#003FFF !important',
            // borderBottom: '4px solid #162E83',
            borderRadius: "20px !important",
            margin: 0,
            padding: 0,
            '&:hover': {
                backgroundColor: '#BECEFF',

            },
            width: '47%',
            height: 52,


        },
        unselected: {
            // borderBottom: '1px solid #D8D8D8',
            // borderRadius: "0px !important",
            borderRadius: "20px !important",
            backgroundColor: '#F4F4F4',
            margin: 0,
            padding: 0,
            '&:hover': {
                backgroundColor: '#BECEFF',

            },
            width: '47%',

            height: 52,

        },
        selectedPhone: {
            // color: 'white',
            // backgroundColor: '#162E83 !important'
            // borderBottom: '4px solid #162E83',
            // borderRadius: "0px !important",
            borderRadius: "14px !important",
            backgroundColor: '#003FFF !important',
            height:40
        },
        unselectedPhone: {
            // borderBottom: '1px solid #D8D8D8',
            // borderRadius: "0px !important",
            // backgroundColor:'yellow'

            borderRadius: "14px !important",

            backgroundColor: '#F4F4F4 ',
            height:40

        },
        selector: {
            height: phone ? 30 : 36,
            minWidth: phone ? 70 : 110,
            // borderRadius: phone ? 5 : 16,
            // backgroundColor: '#F1F0F0',
            paddingRight: 8,
            color: 'black',
            borderRight: '1px solid #D8D8D8',






        },
        selectorIcon: {
            paddingRight: phone ? '18px !important' : null
        },
        warningStyle: {
            fontFamily: "Avenir",
            color: "#DC3055 !important",
            fontSize: phone ? 12 : 14,
            fontWeight: 500,
            lineHeight: phone ? "11px" : '16px',
            textAlign: "left",
            marginTop: 8
        },
        selectPaper: {
            backgroundColor: '#F1F0F0',
            borderRadius: 12
        },
        selectIcon: {
            color: 'black'
        }
    })
    const classes = useStyles();
    const dispatch = useDispatch();
    const returnLivePriceQuantity = () => {
        if (coin1Selected === 'ETH' || coin1Selected === 'BTC') {
            return 0.00001
        }
        else {
            return 0.01
        }
    }
    const initCoins = () => {
        dispatch(tradeActions.getLivePrice(
            {
                "token1": coin1Selected,
                "token2": coin2Selected
            },
            {
                "deliverQuantity": returnLivePriceQuantity(),
                "tradeSide": selected
            }, homePage, token))
        if (!homePage) {
            dispatch(tradeActions.getAquaBalance({
                coin: coin1Selected
            }, token))
        }
    }
    const payValueLimit = () => {
        if (coin1Selected === 'ETH') {
            return 0.01
        } else if (coin1Selected === 'BTC') {
            return 0.001
        } else {
            return 100
        }
    }

    useEffect(() => {
        setInitial(false)
        dispatch(fundsActions.getTokenInfo(token))
        initCoins()
        return () => {
        }
    }, []);

    useEffect(() => {
        initCoins()
        return () => {
        }
    }, [coin1Selected, coin2Selected]);

    useEffect(() => {
        if (!initial) {
            initCoins()
        }
        let allCoinList = []
        tokensInfo.forEach(item => {
            if (selected === 'buy') {
                if (item.buyIsOn === true) {
                    if (!allCoinList.includes(item.token)) {
                        allCoinList.push(item.token)
                    }
                    setSelectList1(['CAD'])
                    if (!showUSDT) {
                        setSelectList2(allCoinList.filter(item => item !== 'USDT'))
                    } else {
                        setSelectList2(allCoinList)
                    }

                }
            } else if (selected === 'sell') {
                if (item.sellIsOn === true) {
                    if (!allCoinList.includes(item.token)) {
                        allCoinList.push(item.token)
                    }
                    if (!showUSDT) {
                        setSelectList1(allCoinList.filter(item => item !== 'USDT'))
                    } else {
                        setSelectList1(allCoinList)
                    }
                    setSelectList2(['CAD'])


                }
            }
        })
        return () => {
        }
    }, [selected, tokensInfo]);


    useEffect(() => {

        if (coinFree1 && payValue <= coinFree1.amount && !tradePriceQuoteLoading && payValue >= payValueLimit() && receiveValue > 0 && coin1Selected == aquaBalance.symbol && payValue <= aquaBalance.availableBalance) {
            setSubmitDisable(false)
        } else if (homePage && !tradePriceQuoteLoading) {
            setSubmitDisable(false)
        } else if (kycDetails.status === 'passed') {
            setSubmitDisable(false)
        } else {
            setSubmitDisable(true)
        }
        if (!homePage) {
            if (payValue < payValueLimit() && payValue != "") {
                setAmount1Warning(t('trade-minLimit') + payValueLimit())
            } else if ((!coinFree1 && payValue != "" || coinFree1 && payValue > coinFree1.amount)) {
                setAmount1Warning(t('insufficient_fund'))
            } else if (payValue != "" && (coin1Selected != aquaBalance.symbol || payValue > aquaBalance.availableBalance)) {
                setAmount1Warning(t('aquaBalanceWarning') + roundingDown(aquaBalance.availableBalance, 8) + " " + aquaBalance.symbol)
            } else {
                setAmount1Warning("")
            }
        }
        if (amount1All.current) {
            amount1All.current = false;

            if (selected === 'buy') {
                setReceiveValue(payValue / livePrice)
            } else if (selected === 'sell') {
                setReceiveValue(payValue * livePrice)
            }

        }
        return () => {
        }
    }, [payValue, receiveValue, t, userCapitals, tradePriceQuoteLoading])

    const renderSelections = (item) => {
        const result = []
        item.forEach(element => {
            result.push(
                <MenuItem key={element} value={element} >

                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Avatar variant="square" alt="Coin Icon" style={{ marginRight: 5, width: 18, height: 18, alignSelf: 'center', marginBottom: 2 }} src={getIcons(element)} />
                        <Typography className={classes.font} style={{ fontSize: phone ? 12 : 16, color: '#1C2237', fontWeight: '500' }}>{element}</Typography>
                    </div>


                </MenuItem >)
        })
        return result
    }

    return (
        <div style={{
            // boxShadow: '0px 2px 16px #E4E4E4',

            // margin: 20,
        }}>
            {phone ?
                <div style={{ display: 'flex',alignItems:'center', flexDirection: 'row', height: 40,  justifyContent: 'space-between',marginTop: 16, marginBottom: 8,width:'100%'}} >
                    <div style={{ display: 'flex', flexDirection: 'row', width: '46%', justifyContent: 'space-between', alignItems: "flex-end",marginBottom:24 }}>

                        <Button
                            className={selected === 'buy' ? classes.selectedPhone : classes.unselectedPhone}
                            style={{ width: '50%', textTransform: 'none', marginRight: 8 }}
                            onClick={() => { setSelected('buy'); setCoin1Selected("CAD"); setCoin2Selected('BTC'); setPayValue(''); setReceiveValue(''); }}
                        >
                            {/* <img alt='' src={selected === 'buy' ? buyIconW : buyIcon} style={{ width: 24 }}></img> */}
                            <Typography style={{ fontSize: 14, fontWeight: '500', fontFamily: 'Avenir', color: selected == 'buy' ? "white" : '#9A9A9A' }}>{t('buyC')}</Typography>


                        </Button>

                        <Button
                            className={selected === 'sell' ? classes.selectedPhone : classes.unselectedPhone}
                            style={{ width: '50%', textTransform: 'none' }}
                            onClick={() => { setSelected('sell');; setCoin1Selected("BTC"); setCoin2Selected('CAD'); setPayValue(''); setReceiveValue(''); }}
                        >
                            {/* <img alt='' src={selected === 'sell' ? sellIconW : sellIcon} style={{ width: 24 }}></img> */}
                            <Typography style={{ fontSize: 14, fontWeight: '500', fontFamily: 'Avenir', color: selected == 'sell' ? "white" : '#9A9A9A' }}>{t('sellC')}</Typography>
                        </Button>
                    </div>

                    <Button style={{
                        display: 'flex', alignItems: 'center', flexDirection: 'column',
                        padding: 0,
                        minHeight: 0,
                        minWidth: 0,
                    }}
                        onClick={() => { window.open(i18n.language === 'en' ? "https://otcdocs.yesbit.io/user-guide/quick-trade" : "https://otcdocs.yesbit.io/v/jian-ti-zhong-wen/user-guide/quick-trade") }}

                    >
                        <img src={questionIcon} style={{ height: phone ? 14 : 18, marginRight: 4 }} />
                        <Typography style={{ fontSize: phone ? 12 : 14, color: '#8B96A7', fontWeight: '500', fontFamily: 'Avenir' }}>{t('tradeHelpButton')}</Typography>

                    </Button>
                </div>
                :
                <div style={{ width: '100%',  display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end', marginBottom: 24 }}>

                    <div style={{  display: 'flex', flexDirection: 'row', width: '32%', justifyContent: 'space-between', alignItems: "flex-end" }}>
                        <Button
                            className={selected === 'buy' ? classes.selected : classes.unselected}
                            style={{ textTransform: 'none', borderRadius: 16 }}

                            onClick={() => { setSelected('buy'); setCoin1Selected("CAD"); setCoin2Selected('BTC'); setPayValue(''); setReceiveValue(''); }}
                        >
                            <Typography style={{ fontSize: 20, fontWeight: '500', color: selected == 'buy' ? "white" : '#9A9A9A' }}>  {t('buyC')}</Typography>


                        </Button>
                        <Button
                            className={selected === 'sell' ? classes.selected : classes.unselected}
                            style={{ textTransform: 'none' }}
                            onClick={() => { setSelected('sell');; setCoin1Selected("BTC"); setCoin2Selected('CAD'); setPayValue(''); setReceiveValue(''); }}
                        >

                            <Typography style={{ fontSize: 20, fontWeight: '500', color: selected == 'sell' ? "white" : '#9A9A9A' }}>  {t('sellC')}</Typography>
                        </Button>
                    </div>

                    <Button style={{
                        display: 'flex', alignItems: 'center', flexDirection: 'column',
                        padding: 0,
                        minHeight: 0,
                        minWidth: 0,
                    }}
                        onClick={() => { window.open(i18n.language === 'en' ? "https://otcdocs.yesbit.io/user-guide/quick-trade" : "https://otcdocs.yesbit.io/v/jian-ti-zhong-wen/user-guide/quick-trade") }}

                    >
                        <img src={questionIcon} style={{ height: phone ? 14 : 18, marginRight: 4 }} />
                        <Typography style={{ fontSize: phone ? 12 : 14, color: '#8B96A7', fontWeight: '500', fontFamily: 'Avenir' }}>{t('tradeHelpButton')}</Typography>

                    </Button>
                </div>



            }


            <div style={{
                borderRadius: phone ? 0 : 28, backgroundColor: phone ? 'white' : '#F9F9F9', paddingLeft: phone ? 0 : 40, paddingRight: phone ? 0 : 40, paddingBottom: 40, paddingTop: phone ? 0 : 40,
            }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: phone ? 0 : 8 }}>
                    <Typography className={classes.label}>
                        {t('wantToPay')}
                    </Typography>
                    {
                        homePage ? null :
                            <Typography className={classes.label} style={{ color: '#8B96A7' }}>
                                {t('balance')}: {coinFree1 ? coin1Selected === 'CAD' ? roundingDown(coinFree1.amount, 2) : roundingDown(coinFree1.amount, 8) : 0}
                            </Typography>
                    }

                </div>
                <CustomTextField
                    placeholder={t('0.00')}
                    id="amount1"
                    name="amount1"
                    phone={phone ? 1 : 0}
                    style={{ width: '100%', textTransform: 'none', marginTop: 6 }}
                    onChange={(e) => {
                        setPayValue(e.target.value);
                        if (inputFocused1) {
                            if (selected === 'buy') {
                                setReceiveValue(e.target.value / livePrice)
                            } else if (selected === 'sell') {
                                setReceiveValue(e.target.value * livePrice)
                            }

                        }
                    }}
                    value={payValue}
                    customtype="number"
                    helperText={amount1Warning}
                    error={amount1Warning !== ''}
                    FormHelperTextProps={{
                        className: classes.warningStyle
                    }}
                    disabled={getLivePriceLoading || getAquaBalanceLoading}
                    onFocus={() => {
                        setInputFocused1(true)
                    }}
                    onBlur={() => {
                        setInputFocused1(false)
                    }}

                    rightbuttonlabel={homePage ? null : t('all')}
                    rightbuttondisable={getLivePriceLoading || getAquaBalanceLoading}
                    rightbuttonclick={() => {
                        amount1All.current = true
                        if (coinFree1) {
                            if (roundingDown(coinFree1.amount, 8) != payValue) {
                                setPayValue(roundingDown(coinFree1.amount, 8))
                            } else {
                                amount1All.current = false
                            }
                        } else {
                            setPayValue('0')
                        }
                    }}
                    coinselect={
                        <TextField
                            id="outlined-select-currency"
                            className={classes.selector}
                            select
                            InputProps={{ style: { color: 'black', height: '100%', alignItems: 'center' }, disableUnderline: true }}
                            value={coin1Selected}
                            onChange={event => { setCoin1Selected(event.target.value); setPayValue(''); setReceiveValue('') }}
                            SelectProps={{
                                classes: { select: classes.selectorIcon, icon: classes.selectIcon },
                                MenuProps: { classes: { paper: classes.selectPaper } }
                            }}
                        >
                            {renderSelections(selectList1)}
                        </TextField>
                    }
                >
                </CustomTextField>
                <Typography className={classes.label} style={{ marginTop: 16 }}>
                    {t('toReceive')}
                </Typography>
                <CustomTextField
                    id="amount2"
                    name="amount2"
                    placeholder={t('0.00')}
                    phone={phone ? 1 : 0}
                    style={{ width: '100%', textTransform: 'none', marginTop: 6 }}
                    value={receiveValue}
                    customtype="number"
                    onChange={(e) => {
                        setReceiveValue(e.target.value);
                        if (inputFocused2) {
                            if (selected === 'buy') {
                                setPayValue(e.target.value * livePrice)
                            } else if (selected === 'sell') {
                                setPayValue(e.target.value / livePrice)
                            }

                        }
                    }}
                    onFocus={() => { setInputFocused2(true) }}
                    onBlur={() => { setInputFocused2(false) }}
                    disabled={getLivePriceLoading || getAquaBalanceLoading}
                    coinselect={
                        <TextField
                            id="outlined-select-currency"
                            className={classes.selector}
                            select
                            InputProps={{ style: { color: 'white', height: '100%', alignItems: 'center' }, disableUnderline: true }}
                            value={coin2Selected}
                            onChange={event => { setCoin2Selected(event.target.value); setPayValue(''); setReceiveValue('') }}
                            SelectProps={{
                                classes: { select: classes.selectorIcon, icon: classes.selectIcon },
                                MenuProps: { classes: { paper: classes.selectPaper } }
                            }}
                        >
                            {renderSelections(selectList2)}
                        </TextField>
                    }
                >
                </CustomTextField>

                <Button className={`${classes.button} ${classes.font}`} style={{ fontSize: phone ? 16 : 20, backgroundColor: submitDisable ? "#EFEFEF" : '#162E83' }} disabled={submitDisable} onClick={next}>{t('next')}</Button>
                <Typography className={classes.font} style={{ marginTop: phone ? 15 : 30, fontSize: phone ? 12 : 14, textAlign: 'center', color: '#8B96a7' }}>
                    {t('livePrice')} 1 {selected === 'buy' ? coin2Selected : coin1Selected} = {livePrice === 0 ? 0 : roundingDown(livePrice, 4)} {selected === 'buy' ? coin1Selected : coin2Selected}
                </Typography>
            </div>

        </div >
    );
}

export default withTranslation()(QuickTradeModal);
