import React, { useEffect, useState } from 'react'
import { withTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
    Avatar,
    makeStyles,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TableHead,
    TablePagination,
    TableRow,
    Typography,
    withStyles
} from '@material-ui/core';
import "react-datepicker/dist/react-datepicker.css";
import { useHistory } from "react-router-dom";
import { convertTimeString, getScan, roundingDown } from "../../utils/Common";
import './FundHistory.css'
import eTransfer from "../../images/funds/eTransfer.png";
import cSupport from "../../images/funds/cSupport.png";
import empty from "../../images/empty.png";
import { fundsActions } from "../../redux/actions/fundsActions";
import { tradeActions } from "../../redux/actions/tradeActions";

function FundHistory({ t, width, height, rowCount, showPage, showCrypto }) {
    const dispatch = useDispatch();
    const { fundsFiatTransactions, fundsCryptoTransactions } = useSelector(state => state.funds)
    const [phone, setPhone] = useState(width <= 745);
    const [loaded, setLoaded] = useState(false)

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const [cryptoPage, setCryptoPage] = React.useState(0);
    const [cryptoRowsPerPage, setCryptoRowsPerPage] = React.useState(10);

    const minHidden = 0.1

    const handleChangeCryptoPage = (event, newCryptoPage) => {
        setCryptoPage(newCryptoPage);
        dispatch(fundsActions.getFundTransactionByCoinType({ types: ['cryptoDeposit', 'cryptoWithdraw'], depositWithdrawType: 'crypto', currentPage: newCryptoPage, pageSize: cryptoRowsPerPage }))
    };

    const handleChangeCryptoRowsPerPage = (event) => {
        setCryptoRowsPerPage(parseInt(event.target.value, 10));
        setCryptoPage(0);
        dispatch(fundsActions.getFundTransactionByCoinType({ types: ['cryptoDeposit', 'cryptoWithdraw'], depositWithdrawType: 'crypto', currentPage: 0, pageSize: event.target.value }))
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        dispatch(fundsActions.getFundTransactionByCoinType({ types: ['deposit', 'withdraw'], depositWithdrawType: 'fiat', currentPage: newPage, pageSize: rowsPerPage }))
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
        dispatch(fundsActions.getFundTransactionByCoinType({ types: ['deposit', 'withdraw'], depositWithdrawType: 'fiat', currentPage: 0, pageSize: event.target.value }))
    };

    useEffect(() => {
        if (showCrypto) {
            dispatch(fundsActions.getFundTransactionByCoinType({ types: ['cryptoDeposit', 'cryptoWithdraw'], depositWithdrawType: 'crypto', currentPage: 0, pageSize: 10 }))
        } else {
            dispatch(fundsActions.getFundTransactionByCoinType({ types: ['deposit', 'withdraw'], depositWithdrawType: 'fiat', currentPage: 0, pageSize: 10 }))
        }
    }, [])

    useEffect(() => {
        if (showCrypto) {
            setCryptoPage(0)
            setCryptoRowsPerPage(10)
            dispatch(fundsActions.getFundTransactionByCoinType({ types: ['cryptoDeposit', 'cryptoWithdraw'], depositWithdrawType: 'crypto', currentPage: 0, pageSize: 10 }))
        } else {
            setPage(0)
            setRowsPerPage(10)
            dispatch(fundsActions.getFundTransactionByCoinType({ types: ['deposit', 'withdraw'], depositWithdrawType: 'fiat', currentPage: 0, pageSize: 10 }))
        }
        return () => {
        }
    }, [showCrypto])

    const StyledTableCell = withStyles((theme) => ({
        root: {
            borderBottom: 'none',

            padding: 0,
            "&:first-child": {
                paddingLeft: 32,
                borderTopLeftRadius: "25px !important",
                borderBottomLeftRadius: "25px !important"
            },
            "&:last-child": {
                paddingRight: 32,
                borderTopRightRadius: "25px !important",
                borderBottomRightRadius: "25px !important"
            }
        },
        head: {
            // backgroundColor: 'red',
            color: '#9097A1',
            fontSize: 14,
            fontWeight: 400,
            paddingLeft: 80,

        },
        body: {
            color: '#1C2237',

            padding: '24px 0px',
            fontWeight: 900,
            paddingLeft: 58,
            backgroundColor: '#F9F9F9',
        }
    }))(TableCell);

    const useStyles = makeStyles({
        table: {
            borderCollapse: 'separate',
            borderSpacing: phone ? "0" : '0px 15px'

        },
        font: {
            fontFamily: "PingFang SC",
            fontSize: 8,
            fontWeight: 500,
            color: "#1C2237"
        },
        pagination: {
            flexDirection: 'column',
            margin: 'auto',
        },
        paginationSelect: {
            margin: 'auto',
            fontSize: 10
        }
    });
    const classes = useStyles();
    const history = useHistory();

    const statusColor = {
        'pending': '#0173F9',
        'canceled': '#9097A1',
        'succeed': '#4D8C7B',
        'expired': '#9097A1',
        'declined': '#9097A1',
        'processing': '#0173F9',
        'failed': '#DC3055'
    }

    const paymentMethod = {
        'InteracETransfer': eTransfer,
        'CustomerSupport': cSupport,
    }

    const renderPhoneList = () => {
        console.log('fundsCryptoTransactions: ', fundsCryptoTransactions.list)
        return (
            <div style={{ display: 'flex', width: '100%', flexDirection: 'column' }}>
                <div style={{ width: width, padding: 8 }} >
                    {
                        // list.list.map(item => (
                        //     showCrypto ?
                        showCrypto ?
                            fundsCryptoTransactions.list.map(item => (
                                item.type === "cryptoDeposit" && item.cryptoType === 'USDT' && item.cryptoAmount < 1 ?
                                 null :
                                    <div key={item.reference} style={{ borderRadius: 0, padding: 0, flexDirection: 'column', justifyContent: 'center', paddingLeft: 16, paddingRight: 16, display: 'flex', alignItems: 'flex-start', marginTop: 12 }}
                                    >
                                        <div style={{ paddingTop: 3, paddingBottom: 3, paddingLeft: 6, paddingRight: 6, backgroundColor: "#f0f0f0", borderRadius: 5, display: 'flex', marginBottom: 8 }}>
                                            <Typography style={{ fontSize: 12, fontWeight: 500, color: item.type == "cryptoWithdraw" ? '#DC3055' : "#0173F9" }} >{item.type === 'cryptoWithdraw' ? t(item.type) : t('funding')} </Typography>
                                        </div>
                                        <div style={{ width: '100%', justifyContent: 'space-between', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>

                                            {/* <div className={classes.listItem}>
                                            <Typography className={classes.title}>{t(item.type)}</Typography>
                                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                <Typography className={classes.title}>{t('transactionNumber')}</Typography>
                                                <Typography style={{ paddingLeft: 3 }} className={classes.content}>{item.reference}</Typography>
                                            </div>
                                        </div> */}

                                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

                                                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
                                                    <Typography style={{ fontSize: 12, fontWeight: 500, color: "#1C2237" }}>{item.reference}</Typography>
                                                    <Typography style={{ fontSize: 12, fontWeight: 500, color: "#8B96A7", marginTop: 4 }}>{`${convertTimeString(item.createdAt)} (UTC)`}</Typography>
                                                    <Typography style={{ fontSize: 12, fontWeight: 500, color: statusColor[item.status] }}>{t(item.status)}</Typography>

                                                </div>

                                            </div>

                                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                                                <div style={{ display: 'flex', alignItems: 'flex-end' }}>
                                                    <Typography style={{ fontSize: 12, fontWeight: 500, color: "black" }}>{`${roundingDown(item.cryptoAmount, 8)} ${item.cryptoType}`}</Typography>
                                                </div>
                                                <div style={{ display: 'flex', alignItems: 'flex-end', marginTop: 4 }}>
                                                    <Typography style={{ fontSize: 12, fontWeight: 500, color: "#8B96A7" }}>{t('fee')}: {roundingDown(item.feeAmount, 8)}</Typography>

                                                </div>
                                                <div style={{ display: 'flex', alignItems: 'flex-end', marginTop: 4 }}>
                                                    <Typography style={{ fontSize: 12, fontWeight: 500, color: statusColor[item.status] }}>
                                                        {
                                                            item.txsHash === null || item.txsHash === undefined ?
                                                                '--' :
                                                                <a style={{ color: '#0173F9' }} href={`${getScan(item.network, false)}${item.txsHash}`} target="_blank">
                                                                    {
                                                                        `${item.txsHash.substring(0, 10)}...${item.txsHash.substring(item.txsHash.length - 4, item.txsHash.length)}`
                                                                    }
                                                                </a>
                                                        }
                                                    </Typography>

                                                </div>


                                            </div>

                                            {/* <div className={classes.listItem}>
                                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                                <Typography style={{ display: 'flex', justifyContent: 'flex-start' }} className={classes.title}>{t('amount')}</Typography>
                                                <Typography style={{ fontSize: 10, paddingLeft: 10 }} className={classes.content}>{`${roundingDown(item.cryptoAmount, 2)} ${item.cryptoType}`}</Typography>
                                            </div>
                                        </div> */}
                                            {/* <div className={classes.listItem}>
                                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                                <Typography style={{ display: 'flex', justifyContent: 'flex-start' }} className={classes.title}>{t('hash')}</Typography>
                                                <Typography style={{ paddingLeft: 10 }} className={classes.content}>{
                                                    item.txsHash === null || item.txsHash === undefined ?
                                                        '--' :
                                                        <a style={{ color: '#0173F9' }} href={`${getScan(item.network, false)}${item.txsHash}`} target="_blank">
                                                            {
                                                                `${item.txsHash.substring(0, 10)}...${item.txsHash.substring(item.txsHash.length - 4, item.txsHash.length)}`
                                                            }
                                                        </a>
                                                }
                                                </Typography>
                                            </div>
                                        </div>
                                        <div className={classes.listItem}>
                                            <Typography className={classes.content}>{`${convertTimeString(item.createdAt)} (UTC)`}</Typography>
                                            <Typography className={classes.content} style={{ color: statusColor[item.status] }}>{t(item.status)}</Typography>
                                        </div> */}
                                        </div>
                                        <div style={{ height: 1, width: '100%', backgroundColor: '#F2F2F2', marginTop: 12 }}></div>

                                    </div>))
                            :
                            fundsFiatTransactions.list.map(item => (
                                <div key={item.reference} style={{ borderRadius: 0, padding: 0, flexDirection: 'column', justifyContent: 'center', paddingLeft: 16, paddingRight: 16, display: 'flex', alignItems: 'flex-start', marginTop: 12 }}
                                >
                                    <div style={{ paddingTop: 3, paddingBottom: 3, paddingLeft: 6, paddingRight: 6, backgroundColor: "#f0f0f0", borderRadius: 5, display: 'flex', marginBottom: 8 }}>
                                        <Typography style={{ fontSize: 12, fontWeight: 500, color: item.type == "deposit" ? "#0173F9" : '#DC3055' }} >{item.type === 'deposit' ? t('funding') : t(item.type)}</Typography>
                                    </div>

                                    <div style={{ width: '100%', justifyContent: 'space-between', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

                                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
                                                <Typography style={{ fontSize: 12, fontWeight: 500, color: "#1C2237" }}>{
                                                    item.paymentMethod === 'InteracETransfer' && item.type === 'deposit' ?
                                                        <span style={{ color: '#0173F9', cursor: 'pointer', textDecoration: 'underline' }} onClick={() => {
                                                            window.open(item.apayloRedirectUrl, '_blank')
                                                        }} target="_blank">
                                                    {item.reference}
                                                </span> :
                                                        item.reference
                                                }</Typography>
                                                <Typography style={{ fontSize: 12, fontWeight: 500, color: "#8B96A7", marginTop: 4 }}>{`${convertTimeString(item.createdAt)} (UTC)`}</Typography>
                                            </div>
                                        </div>

                                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                                            <div style={{ display: 'flex', alignItems: 'flex-end' }}>
                                                <Typography style={{ fontSize: 12, fontWeight: 500, color: "black" }}>{`${roundingDown(item.fiatAmount, 2)} ${item.fiatType}`}</Typography>
                                            </div>
                                            <div style={{ display: 'flex', alignItems: 'flex-end', marginTop: 4 }}>
                                                <Typography style={{ fontSize: 12, fontWeight: 500, color: "#8B96A7", marginRight: 4 }}>{item.type === 'deposit' ? t(`${item.paymentMethod}`) : t(`${item.paymentMethod}Withdraw`)}</Typography>
                                                <Typography style={{ fontSize: 12, fontWeight: 500, color: statusColor[item.status] }}>{t(item.status)}</Typography>

                                            </div>

                                        </div>

                                    </div>
                                    <div style={{ height: 1, width: '100%', backgroundColor: '#F2F2F2', marginTop: 12 }}></div>

                                </div>
                            ))
                    }
                </div>

            </div>
        )
    }


    return (


        <div>
            {phone ? renderPhoneList() :
                <div style={{
                    width: 0.85 * width,
                    height: phone && showPage ? "95%" : '80%',
                    alignItems: phone && showPage ? "flex-start" : 'center',
                    justifyContent: 'center',
                    display: 'flex',
                    marginTop: 15,
                    padding: 0
                }}>
                    <TableContainer
                        style={{
                            background: 'transparent',
                            alignItems: 'center',
                            justifyContent: 'center',
                            borderRadius: 20,
                            border: 'none',
                            boxShadow: 'none'
                        }}>
                        <Table className={classes.table} aria-label="customized table">
                            {phone && showPage ?
                                null
                                :
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell>{t('transactionNumber')}</StyledTableCell>
                                        <StyledTableCell align="left">{t('type')}</StyledTableCell>
                                        <StyledTableCell align="right">{t('amount')}</StyledTableCell>
                                        <StyledTableCell align="right">{showCrypto ? t('fee') : t('paymentMethod')}</StyledTableCell>
                                        {
                                            showCrypto ?
                                                <StyledTableCell align="right">{t('hash')}</StyledTableCell> : null

                                        }
                                        <StyledTableCell align="right">{t('time')}</StyledTableCell>
                                        <StyledTableCell align="right">{t('status')}</StyledTableCell>
                                    </TableRow>
                                </TableHead>
                            }

                            <TableBody>
                                {(showCrypto ? fundsCryptoTransactions.list : fundsFiatTransactions.list
                                ).map((row, index) => (
                                    row.type === "cryptoDeposit" && row.cryptoType === 'USDT' && row.cryptoAmount < minHidden ?
                                        null :
                                    <TableRow key={row.reference} className='styledTableRow'>
                                        <StyledTableCell align="left" >{
                                            row.paymentMethod === 'InteracETransfer' && row.type === 'deposit' ?
                                                <span style={{ color: '#0173F9', cursor: 'pointer', textDecoration: 'underline' }} onClick={() => {
                                                    window.open(row.apayloRedirectUrl, '_blank')
                                                }} target="_blank">
                                                    {row.reference}
                                                </span> :
                                                row.reference
                                        }</StyledTableCell>
                                        <StyledTableCell align="center"><Typography style={{ textAlign: 'left', marginLeft: 22, fontSize: 14, fontWeight: '500' }}>{row.type === 'deposit' ? t('funding') : t(row.type)}</Typography></StyledTableCell>
                                        <StyledTableCell
                                            align="right">{showCrypto ? `${roundingDown(row.cryptoAmount, 8)} ${row.cryptoType}` : `$${roundingDown(row.fiatAmount, 2)}`}</StyledTableCell>
                                        {
                                            row.type === 'cryptoWithdraw' ?
                                                <StyledTableCell align="right">{roundingDown(row.feeAmount, 8)}</StyledTableCell>
                                                :
                                                row.type === 'cryptoDeposit' ?
                                                    <StyledTableCell align="right">{'--'}</StyledTableCell>
                                                    :
                                                    <StyledTableCell align="right">{row.type === 'deposit' ? t(`${row.paymentMethod}`) : t(`${row.paymentMethod}Withdraw`)}</StyledTableCell>
                                        }
                                        {
                                            showCrypto ?
                                                <StyledTableCell align="right"  >
                                                    {
                                                        row.txsHash === null ? '--' :
                                                            <a style={{ color: '#0173F9' }} href={`${getScan(row.network, false)}${row.txsHash}`} target="_blank">
                                                                {
                                                                    `${row.txsHash.substring(0, 10)}...${row.txsHash.substring(row.txsHash.length - 4, row.txsHash.length)}`
                                                                }
                                                            </a>
                                                    }
                                                </StyledTableCell> : null

                                        }
                                        <StyledTableCell align="right">{`${convertTimeString(row.createdAt)} (UTC)`}</StyledTableCell>
                                        <StyledTableCell style={{ color: statusColor[row.status] }}
                                            align="right">{t(row.status)}</StyledTableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                            {
                                showCrypto ?
                                    showPage && showCrypto && fundsCryptoTransactions.list.length !== 0 ?
                                        <TableFooter>
                                            <TableRow>
                                                <TablePagination
                                                    rowsPerPageOptions={[5, 10, 25]}
                                                    count={fundsCryptoTransactions.total}
                                                    rowsPerPage={cryptoRowsPerPage}
                                                    page={cryptoPage}
                                                    SelectProps={{
                                                        inputProps: { 'aria-label': 'rows per page' },
                                                        native: true,
                                                    }}
                                                    labelRowsPerPage={t('rowsPerPage')}
                                                    labelDisplayedRows={(arg) => {
                                                        return `${arg.from}-${arg.to} ${t('paginationOf')} ${arg.count}`
                                                    }}
                                                    onPageChange={handleChangeCryptoPage}
                                                    onRowsPerPageChange={handleChangeCryptoRowsPerPage}
                                                    classes={phone ? {
                                                        selectRoot: classes.paginationSelect,
                                                        toolbar: classes.pagination,
                                                        actions: classes.pagination,
                                                        caption: classes.paginationSelect
                                                    } : null}
                                                />
                                            </TableRow>
                                        </TableFooter> : null :
                                    showPage && !showCrypto && fundsFiatTransactions.list.length !== 0 ?
                                        <TableFooter>
                                            <TableRow>
                                                <TablePagination
                                                    style={{ backgroundColor: '#f1f0f0' }}

                                                    rowsPerPageOptions={[5, 10, 25]}
                                                    count={fundsFiatTransactions.total}
                                                    rowsPerPage={rowsPerPage}
                                                    page={page}
                                                    SelectProps={{
                                                        inputProps: { 'aria-label': 'rows per page' },
                                                        native: true,
                                                    }}
                                                    labelRowsPerPage={t('rowsPerPage')}
                                                    labelDisplayedRows={(arg) => {
                                                        return `${arg.from}-${arg.to} ${t('paginationOf')} ${arg.count}`
                                                    }}
                                                    onPageChange={handleChangePage}
                                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                                    classes={phone ? {
                                                        selectRoot: classes.paginationSelect,
                                                        toolbar: classes.pagination,
                                                        actions: classes.pagination,
                                                        caption: classes.paginationSelect
                                                    } : null}
                                                />
                                            </TableRow>
                                        </TableFooter> : null

                            }
                        </Table>
                    </TableContainer>
                </div>
            }
            {showCrypto ?
                fundsCryptoTransactions.list.length === 0 ?
                    <div>
                        <div style={{ display: 'flex', justifyContent: "center" }}>
                            <img alt="" src={empty} style={{ width: 190, height: 190 }} />
                        </div>
                        <Typography style={{ fontFamily: "Avenir", marginTop: 25, fontSize: 14, color: "#333333", fontWeight: 900 }}>{t('historyEmpty')}</Typography>
                    </div>
                    :
                    null
                :
                fundsFiatTransactions.list.length === 0 ?
                    <div>
                        <div style={{ display: 'flex', justifyContent: "center" }}>
                            <img alt="" src={empty} style={{ width: 190, height: 190 }} />
                        </div>
                        <Typography style={{ fontFamily: "Avenir", marginTop: 25, fontSize: 14, color: "#333333", fontWeight: 900 }}>{t('historyEmpty')}</Typography>
                    </div>
                    :
                    null
            }
        </div>
    )
}
export default withTranslation()(FundHistory)
