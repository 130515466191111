import { fundsService } from "../services/fundsServices";
import {
    GET_USER_CAPITALS, GET_USER_CAPITALS_SUCCEED, GET_USER_CAPITALS_FAILED,
    GET_FUNDS_TRANSACTIONS, GET_FUNDS_FIAT_TRANSACTIONS_SUCCEED, GET_FUNDS_CRYPTO_TRANSACTIONS_SUCCEED, GET_FUNDS_TRANSACTIONS_FAILED,
    FIAT_DEPOSIT, FIAT_DEPOSIT_SUCCEED, FIAT_DEPOSIT_FAILED,
    PRICE_QUOTE, PRICE_QUOTE_SUCCEED, PRICE_QUOTE_FAILED, CLEAR_DEPOSIT, FIAT_WITHDRAW, FIAT_WITHDRAW_SUCCEED, FIAT_WITHDRAW_FAILED, CLEAR_WITHDRAW,
    CRYPTO_WITHDRAW, CRYPTO_WITHDRAW_FAILED, CRYPTO_WITHDRAW_SUCCEED, GET_ADDRESS, GET_ADDRESS_FAILED, GET_ADDRESS_SUCCEED,
    GET_TOKEN_INFO, GET_TOKEN_INFO_FAILED, GET_TOKEN_INFO_SUCCEED, GET_FEE, GET_FEE_FAILED, GET_FEE_SUCCEED, GET_BTC_QUOTE, GET_BTC_QUOTE_FAILED, GET_BTC_QUOTE_SUCCEED,
    GET_ETH_QUOTE, GET_ETH_QUOTE_FAILED, GET_ETH_QUOTE_SUCCEED
} from '../constants';

export const fundsActions = {
    getUserCapital,
    getFundsTransaction,
    fiatDeposit,
    priceQuote,
    priceQuoteNoAuth,
    clear,
    fiatWithdraw,
    clearWithdraw,
    getFundTransactionByCoinType,
    cryptoWithdraw,
    getFeeWithActionAndAmount,
    getAddressByChain,
    getTokenInfo,
    getETHQuote,
    getBTCQuote,
    priceETHNoAuth,
    priceBTCNoAuth
}

function clear() {
    return dispatch => {
        dispatch(clear(false))
    }
    function clear(token) { return { type: CLEAR_DEPOSIT, token } }
}

function clearWithdraw() {
    return dispatch => {
        dispatch(clearWithdraw(false))
    }
    function clearWithdraw(token) { return { type: CLEAR_WITHDRAW, token } }
}

function getUserCapital(token) {
    return dispatch => {
        dispatch(request(token));
        fundsService.getUserCapital(token)
            .then(
                res => {
                    if (res) {
                        dispatch(success(res.data));
                    }
                },
                error => {
                    dispatch(failure({ message: 'tokenExpired' }));
                }
            );
    };

    function request(token) { return { type: GET_USER_CAPITALS, token } }
    function success(data) { return { type: GET_USER_CAPITALS_SUCCEED, data } }
    function failure(message) { return { type: GET_USER_CAPITALS_FAILED, message } }
}

function getFundsTransaction() {
    return dispatch => {
        const token = localStorage.getItem("otcuser")
        if (token && token != "") {
            dispatch(request(token));
            fundsService.getFundsTransaction(token)
                .then(
                    res => {
                        if (res) {
                            dispatch(success(res.data.list));
                        }
                    },
                    error => {
                        dispatch(failure(error.message));
                    }
                );
        } else {
            dispatch(failure({ message: 'tokenExpired' }));
        }
    };

    function request(token) { return { type: GET_FUNDS_TRANSACTIONS, token } }
    function success(data) { return { type: GET_FUNDS_FIAT_TRANSACTIONS_SUCCEED, data } }
    function failure(message) { return { type: GET_FUNDS_TRANSACTIONS_FAILED, message } }
}

function fiatDeposit(payload) {
    return dispatch => {
        const token = localStorage.getItem("otcuser")
        if (token && token != "") {
            dispatch(request(token));
            fundsService.fiatDeposit(token, payload)
                .then(
                    res => {
                        if (res) {
                            dispatch(success(res.data));
                        }
                    },
                    error => {
                        console.log('deposit action error: ', error)
                        if (error.message.includes("Daily e-transfer limit exceeded.")) {
                            dispatch(failure('dailyLimitExceed'));
                        } else {
                            dispatch(failure(error.message))
                        }
                    }
                );
        } else {
            dispatch(failure({ message: 'tokenExpired' }));
        }
    };

    function request(token) { return { type: FIAT_DEPOSIT, token, payload } }
    function success(data) { return { type: FIAT_DEPOSIT_SUCCEED, data } }
    function failure(message) { return { type: FIAT_DEPOSIT_FAILED, message } }
}

function priceQuote(payload, token) {
    return dispatch => {
        if (token && token != "") {
            dispatch(request());
            fundsService.priceQuote(payload, token)
                .then(
                    res => {
                        if (res) {
                            dispatch(success(res.data));
                        }
                    },
                    error => {
                        dispatch(failure(error.message));
                    }
                );
        } else {
            dispatch(failure({ message: 'tokenExpired' }));
        }
    };

    function request() { return { type: PRICE_QUOTE, payload } }
    function success(data) { return { type: PRICE_QUOTE_SUCCEED, data } }
    function failure(message) { return { type: PRICE_QUOTE_FAILED, message } }
}

function priceQuoteNoAuth(payload) {

    return dispatch => {

        dispatch(request());
        fundsService.priceQuoteNoAuth(payload)
            .then(
                res => {
                    if (res) {
                        dispatch(success(res.data));
                    }
                },
                error => {
                    dispatch(failure(error.message));

                }
            );

    };

    function request() { return { type: PRICE_QUOTE, payload } }
    function success(data) { return { type: PRICE_QUOTE_SUCCEED, data } }
    function failure(message) { return { type: PRICE_QUOTE_FAILED, message } }
}

function fiatWithdraw(payload) {
    return dispatch => {
        const token = localStorage.getItem("otcuser")
        if (token && token != "") {
            dispatch(request(token));
            fundsService.fiatWithdraw(token, payload)
                .then(
                    res => {
                        if (res) {
                            dispatch(success(res.message));
                        }
                    },
                    error => {
                        console.log('withdraw action error: ', error)
                        if (error.message.includes("Daily e-transfer limit exceeded.")) {
                            dispatch(failure('dailyLimitExceed'));
                        } else {
                            dispatch(failure(error.message))
                        }
                    }
                );
        } else {
            dispatch(failure({ message: 'tokenExpired' }));
        }
    };

    function request(token) { return { type: FIAT_WITHDRAW, token, payload } }
    function success(data) { return { type: FIAT_WITHDRAW_SUCCEED, data } }
    function failure(message) { return { type: FIAT_WITHDRAW_FAILED, message } }
}

function getFundTransactionByCoinType(payload) {
    return dispatch => {
        const token = localStorage.getItem("otcuser")
        if (token && token != "") {
            dispatch(request(token));
            fundsService.getFundTransactionByCoinType(token, payload)
                .then(
                    res => {
                        if (res) {
                            dispatch(success(res.data));
                        }
                    },
                    error => {
                        console.log('error: ', error)
                        dispatch(failure(error.message))
                    }
                );
        } else {
            dispatch(failure({ message: 'tokenExpired' }));
        }
    };

    function request(token) { return { type: GET_FUNDS_TRANSACTIONS, token, payload } }
    function success(data) { return { type: payload.depositWithdrawType === 'crypto' ? GET_FUNDS_CRYPTO_TRANSACTIONS_SUCCEED : GET_FUNDS_FIAT_TRANSACTIONS_SUCCEED, data } }
    function failure(message) { return { type: GET_FUNDS_TRANSACTIONS_FAILED, message } }
}

function cryptoWithdraw(payload) {
    return dispatch => {
        const token = localStorage.getItem("otcuser")
        if (token && token != "") {
            dispatch(request(token));
            fundsService.cryptoWithdraw(token, payload)
                .then(
                    res => {
                        if (res) {
                            dispatch(success(res.message));
                        }
                    },
                    error => {
                        console.log('crypto withdraw failed : ', error, error.message, error.message === 'Wrong email validation code.')
                        if (error.message === 'Wrong email validation code.') {
                            dispatch(failure('wrongEmailCode'))
                        } else if (error.message === 'Wrong phone validation code.') {
                            dispatch(failure('wrongPhoneCode'))
                        } else {
                            dispatch(failure('withdrawNotAvailable'))
                        }
                    }
                );
        } else {
            dispatch(failure({ message: 'tokenExpired' }));
        }
    };

    function request(token) { return { type: CRYPTO_WITHDRAW, token, payload } }
    function success(data) { return { type: CRYPTO_WITHDRAW_SUCCEED, data } }
    function failure(message) { return { type: CRYPTO_WITHDRAW_FAILED, message } }
}

function getFeeWithActionAndAmount(payload) {
    return dispatch => {
        const token = localStorage.getItem("otcuser")
        if (token && token != "") {
            dispatch(request(token));
            fundsService.getFeeWithActionAndAmount(token, payload)
                .then(
                    res => {
                        if (res) {
                            console.log('get fee action res: ', res)
                            dispatch(success(res.data));
                        }
                    },
                    error => {
                        console.log('get fee failed : ', error)
                        dispatch(failure(error.message))
                    }
                );
        } else {
            dispatch(failure({ message: 'tokenExpired' }));
        }
    };

    function request(token) { return { type: GET_FEE, token, payload } }
    function success(data) { return { type: GET_FEE_SUCCEED, data } }
    function failure(message) { return { type: GET_FEE_FAILED, message } }
}

function getAddressByChain(token, payload) {
    return dispatch => {
        if (!token || token === undefined) {
            token = localStorage.getItem("otcuser")
        }
        if (token && token != "") {
            dispatch(request(token));
            fundsService.getAddressByChain(token, payload)
                .then(
                    res => {
                        if (res) {
                            dispatch(success(res.data));
                        }
                    },
                    error => {
                        console.log('get address failed : ', error)
                        dispatch(failure(error.message))
                    }
                );
        } else {
            dispatch(failure({ message: 'tokenExpired' }));
        }
    };

    function request(token) { return { type: GET_ADDRESS, token, payload } }
    function success(data) { return { type: GET_ADDRESS_SUCCEED, data } }
    function failure(message) { return { type: GET_ADDRESS_FAILED, message } }
}

function getTokenInfo(token) {
    return dispatch => {
        dispatch(request(token));
        fundsService.getTokenInfo(token)
            .then(
                res => {
                    if (res) {
                        dispatch(success(res.data));
                    }
                },
                error => {
                    console.log('get token info failed : ', error)
                    dispatch(failure(error.message))
                }
            );
    };

    function request(token) { return { type: GET_TOKEN_INFO, token } }
    function success(data) { return { type: GET_TOKEN_INFO_SUCCEED, data } }
    function failure(message) { return { type: GET_TOKEN_INFO_FAILED, message } }
}

function getBTCQuote(payload, token) {
    return dispatch => {
        if (token && token != "") {
            dispatch(request());
            fundsService.priceQuote(payload, token)
                .then(
                    res => {
                        if (res) {
                            dispatch(success(res.data));
                        }
                    },
                    error => {
                        dispatch(failure(error.message));
                    }
                );
        } else {
            dispatch(failure({ message: 'tokenExpired' }));
        }
    };

    function request() { return { type: GET_BTC_QUOTE, payload } }
    function success(data) { return { type: GET_BTC_QUOTE_SUCCEED, data } }
    function failure(message) { return { type: GET_BTC_QUOTE_FAILED, message } }
}

function getETHQuote(payload, token) {
    return dispatch => {
        if (token && token != "") {
            dispatch(request());
            fundsService.priceQuote(payload, token)
                .then(
                    res => {
                        if (res) {
                            dispatch(success(res.data));
                        }
                    },
                    error => {
                        dispatch(failure(error.message));
                    }
                );
        } else {
            dispatch(failure({ message: 'tokenExpired' }));
        }
    };

    function request() { return { type: GET_ETH_QUOTE, payload } }
    function success(data) { return { type: GET_ETH_QUOTE_SUCCEED, data } }
    function failure(message) { return { type: GET_ETH_QUOTE_FAILED, message } }
}

function priceETHNoAuth(payload) {

    return dispatch => {

        dispatch(request());
        fundsService.priceQuoteNoAuth(payload)
            .then(
                res => {
                    if (res) {
                        dispatch(success(res.data));
                    }
                },
                error => {
                    dispatch(failure(error.message));

                }
            );

    };

    function request() { return { type: GET_ETH_QUOTE, payload } }
    function success(data) { return { type: GET_ETH_QUOTE_SUCCEED, data } }
    function failure(message) { return { type: GET_ETH_QUOTE_FAILED, message } }
}

function priceBTCNoAuth(payload) {

    return dispatch => {

        dispatch(request());
        fundsService.priceQuoteNoAuth(payload)
            .then(
                res => {
                    if (res) {
                        dispatch(success(res.data));
                    }
                },
                error => {
                    dispatch(failure(error.message));

                }
            );

    };

    function request() { return { type: GET_BTC_QUOTE, payload } }
    function success(data) { return { type: GET_BTC_QUOTE_SUCCEED, data } }
    function failure(message) { return { type: GET_BTC_QUOTE_FAILED, message } }
}

