import config from '../config/config.json'

export function checkRoutes() {
    // console.log('checking routes!')
    if ((window.location.host === 'trade.yesbit.io' || window.location.host === 'www.trade.yesbit.io' ) && window.location.pathname === '/') {
        config.redirect && window.location.replace(`https://yesbit.io`)
    }
    if ((window.location.host === 'yesbit.io' || window.location.host === 'www.yesbit.io')
        && window.location.pathname !== '/' && window.location.pathname !== '/otc'
        && window.location.pathname !== '/nft-homepage' && window.location.pathname !== '/referral-info'
        && window.location.pathname !== '/about-us') {
        // console.log('should be here')
        config.redirect && window.location.replace(`https://trade.yesbit.io${window.location.pathname}`)
    } else if ((window.location.pathname === '/about-us' || window.location.pathname === '/nft-homepage' || window.location.pathname === '/referral-info') && (window.location.host !== 'yesbit.io' && window.location.host !== 'www.yesbit.io')) {
        // console.log('here !!!!!!')
        config.redirect && window.location.replace(`https://yesbit.io${window.location.pathname}`)
    } else if ((window.location.pathname === '/otc' || window.location.pathname === '/wealth') && window.location.host !== 'wealth.yesbit.io' && window.location.host !== 'www.wealth.yesbit.io') {
        // console.log('here instead')
        config.redirect && window.location.replace('https://wealth.yesbit.io')
    }
}
