import React, { useEffect, useState } from 'react'
import { withTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { authActions } from "../../redux/actions/authActions";
import {
    Avatar,
    Button,
    InputAdornment,
    InputBase,
    List,
    ListItem,
    ListItemAvatar,
    ListItemSecondaryAction,
    ListItemText,
    makeStyles,
    MenuItem,
    Modal,
    Select,
    TextField,
    Typography
} from '@material-ui/core';
import i18n from '../../i18n';
import { motion } from "framer-motion"
import enterArrow from "../../images/enterArrow.png";

import passed from '../../images/passed.png';
import "react-datepicker/dist/react-datepicker.css";
import { useHistory } from "react-router-dom";
import { kycActions } from "../../redux/actions/kycActions";
import { formDateString, getIcons, isNumeric, isValidAddress, roundingDown } from "../../utils/Common";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import eTransfer from '../../images/funds/eTransfer.png'
import cSupport from '../../images/funds/cSupport.png'
import { ArrowDropDown, ArrowDropUp } from '@material-ui/icons';
import { fundsActions } from "../../redux/actions/fundsActions";
import USDT from "../../images/coins/usdt.png";
import downArrow from "../../images/downArrow.png";
import tickIcon from "../../images/selectedIcon.png";
import cancel from "../../images/x.png";
import ResultModal from "../ResultModal/ResultModal";
import * as crypto from "crypto";
import checkIcon from '../../images/checkIcon.png'
import warningLabel from '../../images/warningLabel.png'
import enterIcon from '../../images/enterIcon.png'
import backToIcon from '../../images/backToIcon.png'
import fiadIcon from '../../images/fiadIcon.png'
import dropdownIcon from '../../images/dropdownIcon.png'
import closePopIcon from '../../images/closePopIcon.png'
// import selectedIcon from '../../images/selectedIcon.png'
import questionIcon from "../../images/questionIcon.png"
import { Helmet } from "react-helmet";

import cryptoIcon from '../../images/cryptoIcon.png'

function Withdraw({ t, width }) {
    const dispatch = useDispatch();
    const { token, userInfo, emailCodeSent, emailCodeMsg } = useSelector(state => state.auth)
    const { kycDetails, fetched, phoneCodeSent } = useSelector(state => state.kyc)
    const { tradeSubmitted, validDate, withdrawMsg, fundActionLoading, tokensInfo, feeInfo, cryptoWithdrawMsg, cryptoWithdrawSubmitted } = useSelector(state => state.funds)
    const { userCapitals } = useSelector(state => state.funds)

    const [phone, setPhone] = useState(width <= 745);
    const [loaded, setLoaded] = useState(false)

    const [inputting, setInputting] = useState(false)
    const [inputMsg, setInputMsg] = useState('')
    const [inputStatus, setInputStatus] = useState(0)
    const [confirming, setConfirming] = useState(false)
    const [submitted, setSubmitted] = useState(false)
    const [canGoToConfirming, setCanGoToConfirming] = useState(false)
    const [canSubmit, setCanSubmit] = useState(false)

    const [withdrawAmount, setWithdrawAmount] = useState('')
    const [currency, setCurrency] = useState('CAD')

    const [eTransferring, setETransferring] = useState(true)

    const [withdrawType, setWithdrawType] = useState('Crypto')
    const [selectingWithdrawType, setSelectingWithdrawType] = useState(true)
    const [canGoToInput, setCanGoToInput] = useState(true)
    const [balance, setBalance] = useState({ amount: 0 })
    const [selectOpen, setSelectOpen] = useState(false)

    const [verificationCode, setVerificationCode] = useState('')
    const [startCounting, setStartCounting] = useState(false)
    const [counter, setCounter] = useState(30);
    const [phoneCodeStatus, setPhoneCodeStatus] = useState(0)
    const [phoneCodeMsg, setPhoneCodeMsg] = useState('')
    const [needSendCode, setNeedSendCode] = useState(true)

    const [localWithdrawMsg, setLocalWithdrawMsg] = useState('')

    const [withdrawingCrypto, setWithdrawingCrypto] = useState(false)
    const [confirmingCrypto, setConfirmingCrypto] = useState(false)

    const [coin, setCoin] = useState('BTC')
    const [chain, setChain] = useState('BTC')
    const [address, setAddress] = useState('')

    const [showCryptoModal, setShowCryptoModal] = useState(false)
    const [showNetworkModal, setShowNetworkModal] = useState(false)
    const [showWithdrawFailedModal, setShowWithdrawFailedModal] = useState(false)
    const [showCryptoWarning, setShowCryptoWarning] = useState(false)

    const [tokenList, setTokenList] = useState([])
    const [chainList, setChainList] = useState([])
    const [withdrawInfo, setWithdrawInfo] = useState([])
    const [coinInfo, setCoinInfo] = useState({})

    const [cryptoWithdrawAmount, setCryptoWithdrawAmount] = useState('')

    const [validAddress, setValidAddress] = useState(false)

    const [canGoToConfirmingCrypto, setCanGoToConfirmingCrypto] = useState(false)

    const [emailCode, setEmailCode] = useState('')
    const [startEmailCounting, setStartEmailCounting] = useState(false)
    const [emailCounter, setEmailCounter] = useState(30);
    const [emailCodeStatus, setEmailCodeStatus] = useState(0)
    const [localEmailCodeMsg, setLocalEmailCodeMsg] = useState('')
    const [localCryptoWithdrawMsg, setLocalCryptoWithdrawMsg] = useState('')

    const [canWithdrawCrypto, setCanWithdrawCrypto] = useState(false)

    const [feeCoin, setFeeCoin] = useState('')
    const [feeBalance, setFeeBalance] = useState({ amount: '' })

    const eTransferMax = 5000
    const cSupportMin = 5000.0000000001
    const eTransferMin = 100
    const cSupportMax = 1000000000000

    const handleSelectChange = (e) => {
        setCurrency(e.target.value);
    }

    const handleWithdrawTypeChange = (e) => {
        if (e.target.value !== undefined && e.target.value !== '') {
            setCanGoToInput(true)
            setWithdrawType(e.target.value);
        } else {
            setCanGoToInput(false)
        }
    }

    const sendVerificationCode = () => {
        dispatch(kycActions.getVerificationCode(token))
        setStartCounting(true)
        setNeedSendCode(false)
    }

    const sendEmailCode = () => {
        dispatch(authActions.sendVerificationCode(userInfo.username));
        setStartEmailCounting(true);
        setEmailCodeStatus(1)
    };

    const handleVerificationCodeChange = (e) => {
        setVerificationCode(e.target.value)
    }

    const handleEmailCodeChange = (e) => {
        setEmailCode(e.target.value)
    }

    const handleCoinChange = (item) => {
        if (item !== undefined && item !== '') {
            setCoin(item);
            setAddress('')
            setCryptoWithdrawAmount('')
        } else {
        }
    }

    const handleChainChange = (chain) => {
        if (chain !== undefined && chain !== '') {
            setChain(chain);
            setAddress('')
            setCryptoWithdrawAmount('')
        } else {
        }
    }

    const handleClose = () => {
        setShowNetworkModal(false)
        setShowCryptoModal(false)
    }

    const useStyles = makeStyles({
        font: {
            fontFamily: "Avenir",
            fontWeight: 500,
            letterSpacing: 0,
            textAlign: 'left'
        },
        p: {
            fontSize: phone ? 12 : 16
        },
        em: {
            fontSize: phone ? 12 : 16
        },
        Typography: {
            fontSize: phone ? 12 : 16
        },
        inputLabel: {
            color: '#9097A1', marginBottom: 8, textAlign: 'initial', fontFamily: 'Avenir', fontSize: 14, fontWeight: 500, letterSpacing: 0, lineHeight: '22px',
        },
        inputLabelPhone: {
            color: '#8B96A7', marginBottom: 4, textAlign: 'initial', fontFamily: 'Avenir', fontSize: 12, fontWeight: 500, letterSpacing: 0, lineHeight: '14px'
        },
        input: {
            borderRadius: 16, backgroundColor: 'white', width: '100%', height: '55px', fontSize: 16, padding: '35px 15px', fontWeight: '500', border: "1px solid #EDEDED", fontFamily: 'Avenir'
        },
        inputPhone: {
            borderRadius: 12, backgroundColor: 'white', width: '100%', height: '40px', fontSize: 14, padding: 14, border: "1px solid #EDEDED", fontFamily: 'Avenir'
        },
        button: {

            height: '65px',
            borderRadius: 20,
            fontFamily: 'Avenir',
            fontSize: 18,
            fontWeight: 500,
            letterSpacing: 0,
            lineHeight: '25px',
            textTransform: 'none',
        },
        buttonPhone: {

            height: '48.3px',
            borderRadius: 12,
            fontFamily: 'Avenir',
            fontSize: 14,
            fontWeight: 500,
            letterSpacing: 0,
            lineHeight: '16px',
            textTransform: 'none',
        },
        next: {
            color: 'white !important',
            backgroundColor: '#162E83',
            "&:hover": {
                backgroundColor: '#081647',
                "@media (hover: none)": {
                    backgroundColor: '#081647',
                    "&:active": {
                        backgroundColor: '#162E83'
                    }
                },
                '&:inactive': {
                    backgroundColor: '#162E83',
                },
            }
        },
        nextPhone: {
            color: 'white !important',
            backgroundColor: '#162E83',
            "&:hover": {
                backgroundColor: '#081647',
                "@media (hover: none)": {
                    backgroundColor: '#081647',
                    "&:active": {
                        backgroundColor: '#162E83'
                    }
                },
                '&:inactive': {
                    backgroundColor: '#162E83',
                },
            }
        },
        back: {
            color: '1C2237 !important',
            backgroundColor: 'transparent',
            transition: 'transform .2s',
            "&:hover": {
                transform: 'scale(1.5)',
                backgroundColor: 'transparent'
            }
        },
        backPhone: {
            color: '1C2237 !important',
            backgroundColor: 'transparent',
            transition: 'transform .2s',
            "&:hover": {
                transform: 'scale(1.5)',
                backgroundColor: 'transparent'
            }
        },
        withdrawTypeSelect: {
            borderRadius: phone ? 12 : 16,
            backgroundColor: '#F1F0F0',
            width: '100%',
            height: phone ? '50px' : '55px',
            fontSize: 22,
            padding: phone ? 15 : '35px 15px',
            fontWeight: '500',
            color: 'white',
            borderBottom: null,
            '&:before': {
                borderColor: 'transparent',
            },
            '&:after': {
                borderColor: 'transparent',
            },
            '& .MuiSelect-selectMenu': {
                display: 'flex',
                fontWeight: '500',
                justifyContent: 'flex-start',
                alignItems: 'center'
            },
            '& .MuiSelect-select': {
                fontSize: 16
            },
            '& .MuiSelect-selected': {
                backgroundColor: 'transparent'
            },
        },
        icon: {
            fill: 'black'
        },
        select: {
            // backgroundColor: '#162E83',
            // borderRadius: 10,
            // color: 'white',
            fontSize: phone ? 12 : 16,
            padding: phone ? 0 : 8,
            borderBottom: null,
            width: phone ? 90 : 120,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            '&:before': {
                borderColor: 'transparent',
            },
            '&:after': {
                borderColor: 'transparent',
            },
            '& .MuiSelect-selectMenu': {
                display: 'flex',
                fontWeight: '500',
                justifyContent: 'center',
                alignItems: 'center'
            },
            '& .MuiSelect-select': {
                fontSize: 12
            }
        },
        selectedPay: {
            backgroundColor: '#0173F9',
            marginBottom: 15, borderRadius: 12, padding: '15px 15px',
            '& span, p': {
                color: 'white'
            },
            '& ListItemText': {
                color: 'white'
            }
        },
        nonSelectedPay: {
            border: '1px solid #858E9D',
            marginBottom: 15, borderRadius: 12, padding: '15px 15px'
        },
        listItem: {
            "& span": {
                fontWeight: '500',
                fontSize: phone ? 14 : 24
            }
        },
        confirming: {
            borderRadius: 16, border: 'solid thin #9097A1', backgroundColor: 'transparent', width: '100%', fontSize: 22, padding: '15px 0'
        },
        confirmingPhone: {
            borderRadius: 12, border: 'solid thin #9097A1', backgroundColor: 'transparent', width: '100%', height: '40px', fontSize: 10, padding: '14px 0',
            alignItems: 'center'
        },
        email: {
            borderRadius: 16, backgroundColor: '#F1F0F0', width: '100%', fontSize: 22, fontWeight: '500', height: '55px', justifyContent: 'center'
        },
        emailPhone: {
            borderRadius: 12, backgroundColor: '#F1F0F0', width: '100%', fontSize: 10, height: '40px', justifyContent: 'center'
        },
        confirmList: {
            padding: 0,
            marginTop: 12
        },
        confirmListTitle: {
            color: '#858E9D',
            fontSize: phone ? 12 : 14,
            fontWeight: 500
        },
        confirmListText: {
            fontSize: phone ? 12 : 14,
            color: '#1C2237',
            fontWeight: '500'
        },
        menu: {
            '& .MuiListItem-root.Mui-selected, .MuiListItem-root.Mui-selected:hover': {
                backgroundColor: 'transparent'
            }
        },
        cryptoSelect: {
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            borderRadius: phone ? 12 : 16,
            backgroundColor: 'white',
            width: 'auto',
            fontSize: 22,
            padding: phone ? '8px 10px' : '10px 15px',
            fontWeight: '500',
            color: 'white',
            borderBottom: null,
            border: "1px solid #EDEDED"
        },
        withdrawNote: {
            height: 38,
            overflow: 'auto',
            listStyle: 'none',
            display: 'contents'
        },
        withdrawNoteText: {
            display: 'flex',
            color: '#0173F9',
            textAlign: 'left',
            fontSize: 12,
            fontWeight: 500,
            alignItems: 'baseline',
            fontFamily: 'Avenir',
        },
        withdrawNoteTitle: {
            color: '#9097A1',
            alignItems: 'center',
            fontSize: 12,
            fontWeight: 500,
            paddingRight: 5,
            fontFamily: 'Avenir',
        },
        submittedTitleText: {
            fontSize: phone ? 12 : 14,
            marginTop: phone ? 8 : 12,
            color: "#858E9D",
            fontWeight: '500',
            textAlign: 'left',

        },
    });
    const classes = useStyles();
    const history = useHistory();

    const getAvailableChain = () => {
        let chainList = tokensInfo.filter(item => item.token === coin && item.withdrawIsOn === true)
        setChainList(chainList)
        if (chainList !== undefined && chainList.length > 0) {
            setChain(chainList[0].chain)
        }

        /** hard code default chain for USDT to be TRX  -- Richard **/
        if (coin === 'USDT') {
            setChain('TRX')
        }
    }

    const getWithdrawInfo = () => {
        let tempWithdrawInfo = tokensInfo.filter(item => item.token === coin)
        // console.log('withdrawInfo: ', tempWithdrawInfo)
        if (tempWithdrawInfo !== undefined) {
            setWithdrawInfo(tempWithdrawInfo)
        }
    }

    const getCoinInfo = () => {
        let coinInfo = tokensInfo.find(item => item.chain === chain && item.token === coin)
        if (coinInfo === undefined) {
            coinInfo = {
                token: '',
            }
        }
        let coinCapital = userCapitals.find(item => item.symbol === coin)
        if (coinCapital === undefined) {
            coinCapital = {
                amount: 0,
            }
        }
        coinInfo.amount = coinCapital.amount

        /** Hard code 6 supported chain remaining daily limit  -- Richard **/
        coinInfo.dailyLimitBTC = coinCapital.withdrawLimitBtc
        coinInfo.dailyLimitETH = coinCapital.withdrawLimitEth
        coinInfo.dailyLimitBSC = coinCapital.withdrawLimitBsc
        coinInfo.dailyLimitHECO = coinCapital.withdrawLimitHeco
        coinInfo.dailyLimitTRX = coinCapital.withdrawLimitTrx
        coinInfo.dailyLimitHSC = coinCapital.withdrawLimitHsc

        setCoinInfo(coinInfo)
    }

    const checkInput = () => {
        if (withdrawAmount === '') {
            setInputStatus(0)
            setInputMsg('')
        } else {
            if (parseFloat(withdrawAmount) <= eTransferMax && parseFloat(withdrawAmount) >= eTransferMin) {
                setETransferring(true)
                if (parseFloat(withdrawAmount) > balance.amount) {
                    setCanGoToConfirming(false)
                    setInputMsg(t('insufficient_fund'))
                    setInputStatus(2)
                } else {
                    if (currency !== '') {
                        setInputStatus(1)
                        setInputMsg('')
                        setCanGoToConfirming(true)
                    } else {
                        setCanGoToConfirming(false)
                    }
                }
            } else {
                setETransferring(false)
                if (parseFloat(withdrawAmount) === 0) {
                    setInputStatus(0)
                } else if (parseFloat(withdrawAmount) < 100 & parseFloat(withdrawAmount) !== 0) {
                    setInputMsg('lessThanMinWithdraw')
                    setInputStatus(2)
                    setCanGoToConfirming(false)

                } else {
                    if (parseFloat(withdrawAmount) > balance.amount) {
                        setCanGoToConfirming(false)
                        setInputMsg(t('insufficient_fund'))
                        setInputStatus(2)
                    } else {
                        if (parseFloat(withdrawAmount) > eTransferMax && currency !== '') {
                            setInputStatus(1)
                            setInputMsg('')
                            setCanGoToConfirming(true)
                        } else {
                            setCanGoToConfirming(false)
                        }
                    }
                }
            }
        }
    }

    const checkCryptoInput = () => {
        // console.log('cryptoWithdrawAmount: ', cryptoWithdrawAmount === '')
        let sameAsWithdraw = feeCoin === coin
        if (cryptoWithdrawAmount === '') {
            setInputStatus(0)
            setInputMsg('')
        } else {
            if (sameAsWithdraw) {
                if (parseFloat(cryptoWithdrawAmount) < coinInfo.minWithdrawAmount + feeInfo.amount) {
                    setInputMsg('lessThanCryptoMinWithdraw')
                    setInputStatus(2)
                } else if (parseFloat(cryptoWithdrawAmount) > coinInfo.amount) {
                    setInputMsg(t('moreThanCryptoWithdrawBalance'))
                    setInputStatus(2)
                } else if (parseFloat(cryptoWithdrawAmount) > coinInfo[`dailyLimit${chain}`]) {
                    setInputMsg(t('moreThanDailyCryptoWithdraw'))
                    setInputStatus(2)
                } else if (parseFloat(cryptoWithdrawAmount) < feeInfo.amount) {
                    setInputMsg(t('lessThanWithdrawFee'))
                    setInputStatus(2)
                } else {
                    setInputMsg('')
                    setInputStatus(1)
                }
            } else {
                if (parseFloat(cryptoWithdrawAmount) < coinInfo.minWithdrawAmount) {
                    setInputMsg('lessThanCryptoMinWithdraw')
                    setInputStatus(2)
                } else if (parseFloat(cryptoWithdrawAmount) > coinInfo.amount) {
                    setInputMsg(t('moreThanCryptoWithdrawBalance'))
                    setInputStatus(2)
                } else if (parseFloat(cryptoWithdrawAmount) > coinInfo[`dailyLimit${chain}`]) {
                    setInputMsg(t('moreThanDailyCryptoWithdraw'))
                    setInputStatus(2)
                } else if (parseFloat(feeBalance.amount) < feeInfo.amount) {
                    setInputMsg(t('lessThanWithdrawFee'))
                    setInputStatus(2)
                } else {
                    setInputMsg('')
                    setInputStatus(1)
                }
            }
        }
    }

    const checkAddress = () => {
        setValidAddress(isValidAddress(address, coinInfo.verifyRegularExpression))
    }

    const handleWithdrawAmountChange = (event) => {
        if (isNumeric(event.target.value)) {
            setWithdrawAmount(event.target.value);
        } else {
            if (event.target.value === '') {
                setWithdrawAmount('')
            } else {
                setWithdrawAmount('0')
            }
        }
    };

    const handleWithdrawAmountFocus = (event) => {
        if (event.target.value === '') {
            setWithdrawAmount(0)
        } else {
            setWithdrawAmount(parseFloat(event.target.value))
        }
    }

    const handleWithdrawAmountBlur = (event) => {
        if (event.target.value === '') {
            setWithdrawAmount(0)
        } else {
            setWithdrawAmount(parseFloat(roundingDown(event.target.value, 2)))
        }
    }

    const handleCryptoWithdrawAmountChange = (event) => {
        if (isNumeric(event.target.value)) {
            setCryptoWithdrawAmount(event.target.value);
        } else {
            if (event.target.value === '') {
                setCryptoWithdrawAmount('')
            } else {
                setCryptoWithdrawAmount('0')
            }
        }
    };

    const handleCryptoWithdrawAmountFocus = (event) => {
        if (event.target.value === '') {
            setWithdrawAmount(0)
        } else {
            setWithdrawAmount(parseFloat(event.target.value))
        }
    }

    const handleCryptoWithdrawAmountBlur = (event) => {
        if (event.target.value === '') {
            setCryptoWithdrawAmount('')
        } else {
            setCryptoWithdrawAmount(parseFloat(roundingDown(event.target.value, 4)))
            checkCryptoInput()
        }
        getFee()
    }

    const goToInput = () => {
        setSelectingWithdrawType(false)
        setWithdrawingCrypto(false)
        setConfirmingCrypto(false)
        setConfirming(false)
        setInputting(true)
        setSubmitted(false)
        setWithdrawType("Fiat")
    }

    const goToWithdrawCrypto = () => {
        setSelectingWithdrawType(false)
        setWithdrawingCrypto(true)
        setConfirmingCrypto(false)
        setConfirming(false)
        setInputting(false)
        setSubmitted(false)
        getFee()
        setWithdrawType("Crypto")
        // getter
        setShowCryptoWarning(true)

    }

    const goBack = () => {
        dispatch(fundsActions.clearWithdraw())
        history.goBack()
    }

    const goBackSelectWithdrawType = () => {
        setConfirming(false)
        setInputting(false)
        setSubmitted(false)
        setWithdrawingCrypto(false)
        setConfirmingCrypto(false)
        setSelectingWithdrawType(true)
        dispatch(fundsActions.clearWithdraw())
    }

    const goBackFromSubmitted = () => {
        setConfirming(false)
        setWithdrawingCrypto(false)
        setConfirmingCrypto(false)
        setInputting(true)
        setSubmitted(false)
        dispatch(fundsActions.clearWithdraw())
        history.goBack()
    }

    const goToConfirming = () => {
        setConfirming(true)
        setInputting(false)
        setSubmitted(false)
        setWithdrawingCrypto(false)
        setConfirmingCrypto(false)
    }

    const goToConfirmingCrypto = () => {
        setConfirmingCrypto(true)
        setConfirming(false)
        setInputting(false)
        setSubmitted(false)
        setWithdrawingCrypto(false)
    }

    const backToInput = () => {
        setConfirming(false)
        setInputting(true)
        setSubmitted(false)
        setWithdrawingCrypto(false)
        setConfirmingCrypto(false)
        dispatch(fundsActions.clearWithdraw())
    }

    const backToWithdrawCrypto = () => {
        setConfirming(false)
        setInputting(false)
        setSubmitted(false)
        setWithdrawingCrypto(true)
        setConfirmingCrypto(false)
    }

    const submit = () => {
        if (typeof withdrawAmount === 'string') {
            setWithdrawAmount(parseFloat(roundingDown(withdrawAmount, 2)))
        }
        let payload = {
            "symbol": currency,
            "amount": withdrawAmount,
            "paymentMethod": eTransferring ? "InteracETransfer" : "CustomerSupport",
            "phoneCode": eTransferring ? verificationCode : ''
        }

        // console.log('submitting withdraw: ', payload)
        dispatch(fundsActions.fiatWithdraw(payload))
        setInputting(false)
    }

    const withdrawCrypto = () => {
        let payload = {
            "currency": coin,
            "chain": chain,
            "amount": parseFloat(roundingDown(cryptoWithdrawAmount, 8)),
            "address": address,
            "memo": '',
            "phoneCode": verificationCode,
            "emailCode": emailCode

        }

        // console.log('submitting crypto withdraw: ', payload)
        dispatch(fundsActions.cryptoWithdraw(payload))
        setInputting(false)
    }



    const allIn = () => {

        if (withdrawType === 'Fiat') {
            setWithdrawAmount(roundingDown(balance.amount, 2))
        } else if (withdrawType === 'Crypto') {
            let amount = Math.min(coinInfo.amount, coinInfo[`dailyLimit${chain}`])
            setCryptoWithdrawAmount(roundingDown(amount, 4))
            getFee(roundingDown(amount, 8))
        }
    }

    const paymentMethod = [
        {
            title: t('InteracETransfer'),
            subTitle: t('eTransferSub'),
            icon: eTransfer,
            range: `$${eTransferMin}-$${eTransferMax}`,
            max: eTransferMax,
            min: eTransferMin,
        },
        {
            title: t('CustomerSupportTitleWithdraw'),
            subTitle: t('cSupportSubWithdraw'),
            icon: cSupport,
            range: '$5000+',
            max: cSupportMax,
            min: cSupportMin,
        },
    ]

    const onSelectOpen = () => {
        setSelectOpen(true)
    }

    const onSelectClose = () => {
        setSelectOpen(false)
    }

    const handleSelectIcon = () => {
        if (selectOpen) {
            return <ArrowDropUp />
        } else {
            return <ArrowDropDown />
        }
    }

    const openCryptoModal = () => {
        setShowCryptoModal(true)
    }

    const openNetworkModal = () => {
        setShowNetworkModal(true)
    }

    const openCryptoWarningModal = () => {
        setShowCryptoModal(true)
    }

    const handleAddressChange = (e) => {
        if (e.target.value !== undefined) {
            setAddress(e.target.value)
        }
    }

    const getFee = (amount) => {
        if (coinInfo !== undefined && coinInfo.withdrawFeeKey !== undefined) {
            let payload = {
                withdrawKey: coinInfo.withdrawFeeKey,
                amount: amount === undefined || amount === null ? cryptoWithdrawAmount === '' ? 0 : parseFloat(cryptoWithdrawAmount) : parseFloat(amount),
            }
            dispatch(fundsActions.getFeeWithActionAndAmount(payload))
        }
    }

    const checkHasETHBTCUSDT = (list) => {
        let hasETH = list.find(item => item.token === 'ETH')
        let hasBTC = list.find(item => item.token === 'BTC')
        let hasUSDT = list.find(item => item.token === 'USDT')
        let ETHOpen = tokensInfo.filter(item => item.token === 'ETH' && item.withdrawIsOn) !== undefined
        let BTCOpen = tokensInfo.filter(item => item.token === 'BTC' && item.withdrawIsOn) !== undefined
        let USDTOpen = tokensInfo.filter(item => item.token === 'USDT' && item.withdrawIsOn) !== undefined
        if (!!!hasUSDT) {
            list.push({
                amount: 0,
                token: 'USDT',
                open: USDTOpen
            })
        }
        if (!!!hasETH) {
            list.push({
                amount: 0,
                token: 'ETH',
                open: ETHOpen
            })
        }
        if (!!!hasBTC) {
            list.push({
                amount: 0,
                token: 'BTC',
                open: BTCOpen
            })
        }
        return list
    }

    useEffect(() => {
        if (fetched) {
            dispatch(kycActions.getKycDetails(token))
        }
    }, [fetched])

    useEffect(() => {
        if (withdrawType === 'Fiat') {
            checkInput()
        }
    }, [currency, withdrawAmount])

    useEffect(() => {
        checkCryptoInput()
    }, [coin, cryptoWithdrawAmount])

    useEffect(() => {
        if (withdrawMsg !== '' && tradeSubmitted) {
            setConfirming(false)
            setInputting(false)
            setSubmitted(true)
        }
        if (withdrawMsg !== '' && !tradeSubmitted) {
            if (eTransferring) {
                setConfirming(true)
                setInputting(false)
                setSubmitted(false)
            } else {
                setConfirming(false)
                setInputting(true)
                setSubmitted(false)
            }

            // setNeedSendCode(true)
        }
        if (cryptoWithdrawMsg === '' && cryptoWithdrawSubmitted) {
            setWithdrawingCrypto(false)
            setConfirmingCrypto(false)
            setSubmitted(true)
            dispatch(fundsActions.getTokenInfo(token))
        }
    }, [tradeSubmitted, withdrawMsg, cryptoWithdrawMsg, cryptoWithdrawSubmitted])

    useEffect(() => {
        // console.log('withdraw res: ', withdrawMsg, tradeSubmitted)
    }, [withdrawMsg, tradeSubmitted])

    useEffect(() => {
        let balance = userCapitals.find(item => item.symbol === currency)
        if (balance !== undefined) {
            // console.log('balance: ', balance)
            setBalance(balance)
        }
    }, [currency, userCapitals])

    useEffect(() => {
        dispatch(authActions.getUserInfo(token))
        dispatch(kycActions.syncKycStatus(token))
        dispatch(kycActions.syncAddressStatus(token))
        dispatch(fundsActions.getUserCapital(token))
        dispatch(fundsActions.getTokenInfo(token))
        goBackSelectWithdrawType()
        setLoaded(true)
        return () => {
        }
    }, []);

    useEffect(() => {
        // console.log('page status: ', selectingWithdrawType, inputting, confirming, submitted)
    }, [selectingWithdrawType, inputting, confirming, submitted])


    useEffect(() => {
        setLocalWithdrawMsg('')
        if (verificationCode === '') {
            setPhoneCodeMsg(t(''))
            setPhoneCodeStatus(0)
        } else {
            if (!phoneCodeSent) {
                setPhoneCodeMsg(t('clickSend'))
                setPhoneCodeStatus(2)
            } else {
                if (verificationCode.length !== 6) {
                    setPhoneCodeMsg(t('invalidPhoneCode'))
                    setPhoneCodeStatus(2)
                } else {
                    setPhoneCodeStatus(1)
                    setPhoneCodeMsg('')
                }
            }
        }
    }, [verificationCode, phoneCodeSent])

    useEffect(() => {
        setLocalCryptoWithdrawMsg('')
        if (emailCode === '') {
            setLocalEmailCodeMsg(t(''))
            setEmailCodeStatus(0)
        } else {
            if (!emailCodeSent) {
                setLocalEmailCodeMsg(t('clickSend'))
                setEmailCodeStatus(2)
            } else {
                if (emailCodeMsg !== '') {
                    setLocalEmailCodeMsg(emailCodeMsg)
                }
                if (emailCode.length !== 6) {
                    setLocalEmailCodeMsg(t('invalidPhoneCode'))
                    setEmailCodeStatus(2)
                } else {
                    setEmailCodeStatus(1)
                    setLocalEmailCodeMsg('')
                }
            }
        }
    }, [emailCode, emailCodeSent, emailCodeMsg])

    useEffect(() => {
        if (startCounting) {
            counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
        }

        if (counter === 0) {
            setStartCounting(false)
            setCounter(30)
        }
    }, [counter, startCounting]);

    useEffect(() => {
        if (startEmailCounting) {
            emailCounter > 0 && setTimeout(() => setEmailCounter(emailCounter - 1), 1000);
        }

        if (emailCounter === 0) {
            setStartEmailCounting(false)
            setEmailCounter(30)
        }
    }, [emailCounter, startEmailCounting]);

    useEffect(() => {
        setLocalWithdrawMsg(withdrawMsg)
    }, [withdrawMsg])

    useEffect(() => {
        setLocalCryptoWithdrawMsg(cryptoWithdrawMsg)
        if (cryptoWithdrawMsg !== '') {
            setShowWithdrawFailedModal(true)
        }
    }, [cryptoWithdrawMsg])

    useEffect(() => {
        let tempList = []
        // console.log('userCapital: ', userCapitals)
        // console.log('tokenInfo: ', tokensInfo)
        for (let i = 0; i < userCapitals.length; i++) {
            let existed = tempList.find(item => item.token === userCapitals[i].symbol)
            let isCrypto = tokensInfo.find(item => item.token === userCapitals[i].symbol)
            if (existed === undefined && isCrypto !== undefined) {
                let open = tokensInfo.filter(item => item.token === userCapitals[i].symbol && item.withdrawIsOn) !== undefined
                tempList.push({
                    token: userCapitals[i].symbol,
                    open: open,
                    amount: userCapitals[i].amount,
                })
            }
        }
        let updatedList = checkHasETHBTCUSDT(tempList)
        console.log('updatedList: ', updatedList)
        setTokenList(updatedList)
        getWithdrawInfo()
        getCoinInfo()
    }, [tokensInfo])

    useEffect(() => {
        getWithdrawInfo()
        getCoinInfo()
    }, [coin, tokensInfo, chain])

    useEffect(() => {
        getAvailableChain()
    }, [coin, tokensInfo])

    useEffect(() => {
        getFee()
    }, [coinInfo, chain])

    useEffect(() => {
        checkAddress()
    }, [address, chain, coinInfo])

    useEffect(() => {
        // console.log('inputStatus: ', inputStatus)
        setCanGoToConfirmingCrypto(chain !== '' && chain !== undefined && coin !== '' && coin !== undefined && validAddress && inputStatus === 1)
    }, [chain, coin, validAddress, inputStatus])

    useEffect(() => {
        // console.log('here: ', emailCodeStatus, phoneCodeSent, phoneCodeStatus, emailCodeSent)
        setCanWithdrawCrypto(emailCodeStatus === 1 && phoneCodeStatus === 1)
    }, [emailCodeStatus, phoneCodeSent, phoneCodeStatus, emailCodeSent])

    useEffect(() => {
        // console.log('showNetworkModal: ', showNetworkModal)
    }, [showNetworkModal])

    useEffect(() => {
        setFeeCoin(feeInfo.base)
        let feeCapitals = userCapitals.find(item => item.symbol === feeInfo.base)
        if (feeCapitals === undefined) {
            feeCapitals = {
                amount: 0
            }
            setFeeBalance(feeCapitals)
        }
    }, [feeInfo])


    const renderSelectWithdrawType = () => {
        return (
            <div>
                {/* <div style={{ marginTop: phone ? 16 : 60, display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography
                        style={
                            phone ? { color: "#1C2237", fontFamily: "Avenir", fontSize: 16, fontWeight: 500, letterSpacing: 0, lineHeight: '22px', textAlign: 'initial' }
                                : { color: "#1C2237", fontFamily: "Avenir", fontSize: 24, fontWeight: 500, letterSpacing: 0, lineHeight: '35px', textAlign: 'initial' }
                        }
                    >
                        {t("withdrawTitle")}
                    </Typography>

                    <Button

                        style={{ opacity: fundActionLoading || !loaded ? 0.3 : 1, backgroundColor:'#F9F9F9',borderRadius:8, textTransform:'none' }}
                        disabled={fundActionLoading || !loaded}
                        onClick={() => { goBack() }}
                    >
                     <Typography style={{ fontSize: phone?  10:14, fontWeight: '500' }}>

                            {t('back')}
                        </Typography>

                    </Button>
                </div> */}
                <div style={{ marginTop: phone ? 16 : 60, display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <Typography
                            style={
                                phone ? { color: "#1C2237", fontFamily: "Avenir", fontSize: 16, fontWeight: 500, letterSpacing: 0, lineHeight: '22px', textAlign: 'initial' }
                                    : { color: "#1C2237", fontFamily: "Avenir", fontSize: 24, fontWeight: 500, letterSpacing: 0, lineHeight: '35px', textAlign: 'initial' }
                            }
                        >
                            {t("withdrawTitle")}
                        </Typography>
                        <Typography style={{ color: '#858E9D', fontSize: phone ? 12 : 14, fontWeight: '500', fontFamily: "Avenir", }} >
                            {t("withdrawCoinSub")}
                        </Typography>
                    </div>
                    <Button

                        style={{ padding: 4 }}
                        disabled={fundActionLoading || !loaded}
                        onClick={() => { goBack() }}
                    >
                        {/* <Typography style={{ fontSize: phone ? 10 : 14, fontWeight: '500' }}>
                            {t('back')}
                        </Typography> */}
                        <img src={backToIcon} style={{ width: phone ? 24 : 32, marginRight: 4 }} />


                    </Button>
                </div>
                <div style={{ height: 1, width: '100%', backgroundColor: '#EFEFEF', marginTop: 12, marginBottom: 36 }} />

                {/* <div style={{ marginBottom: 40, marginTop: phone ? 18 : 24, justifyContent: 'space-between', display: 'flex', flexDirection: 'column' }}>
                    <div style={{ width: '100%' }}>
                        <Typography
                            className={phone ? classes.inputLabelPhone : classes.inputLabel}
                        >
                            {t('withdrawType')}
                        </Typography>
                        <Select
                            value={withdrawType}
                            onChange={(e) => handleWithdrawTypeChange(e)}
                            style={{ color: 'black' }}
                            displayEmpty
                            disableUnderline
                            className={classes.withdrawTypeSelect}
                            inputProps={{
                                'aria-label': 'Without label', classes: {
                                    icon: classes.icon
                                }
                            }}
                            IconComponent={() => handleSelectIcon()}
                            MenuProps={{
                                classes: {
                                    paper: classes.menu
                                }
                            }}
                            onClose={onSelectClose}
                            onOpen={onSelectOpen}
                        >
                            <MenuItem value={'Fiat'}>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <Typography style={{ fontSize: phone ? 12 : 16, fontWeight: 500, padding: 10 }}>{t('fiat')}</Typography>
                                </div>
                            </MenuItem>
                            <MenuItem value={'Crypto'}>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <Typography style={{ fontSize: phone ? 12 : 16, fontWeight: 500, padding: 10 }}>{t('crypto')}</Typography>
                                </div>
                            </MenuItem>
                        </Select>
                    </div>
                </div> */}

                <Button style={{ opacity: fundActionLoading || !loaded || !canGoToInput ? 0.3 : 1, borderRadius: 20, backgroundColor: '#FBFBFB', display: 'flex', alignItems: 'center', height: phone ? 80 : 100, overflow: 'hidden', width: '100%', padding: 0 }} disabled={fundActionLoading || !loaded || !canGoToInput} onClick={() => { goToWithdrawCrypto() }}>
                    <div style={{ display: 'flex', width: '25%', alignItems: 'center', justifyContent: 'center', backgroundColor: '#FFCC00', height: phone ? 80 : 100 }}>
                        <img src={cryptoIcon} style={{ width: phone ? 24 : 32 }} />

                    </div>

                    <div style={{ width: '75%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <div style={{ display: 'flex', alignItems: 'flex-start', flexDirection: 'column', marginLeft: 16 }}>
                            <Typography style={{ fontSize: phone ? 14 : 18, fontWeight: '500', textTransform: 'none', fontFamily: "Avenir", }}>{t('crypto')}</Typography>
                            <div style={{ display: 'flex' }}>
                                {tokenList.map(item => (
                                    <Avatar style={{ width: phone ? 14 : 20, height: phone ? 14 : 20, alignSelf: 'center', marginRight: 8, marginTop: 8 }} src={getIcons(item.token)} variant="square" />
                                ))}
                            </div>
                        </div>
                        <img src={enterIcon} style={{ width: phone ? 24 : 32, marginRight: 16 }} />

                    </div>
                </Button>

                <Button style={{ opacity: fundActionLoading || !loaded || !canGoToInput ? 0.3 : 1, borderRadius: 20, backgroundColor: '#FBFBFB', display: 'flex', alignItems: 'center', height: phone ? 80 : 100, overflow: 'hidden', width: '100%', padding: 0, marginTop: 24 }} disabled={fundActionLoading || !loaded || !canGoToInput} onClick={() => { goToInput() }}>
                    <div style={{ display: 'flex', width: '25%', alignItems: 'center', justifyContent: 'center', backgroundColor: '#06746B', height: phone ? 80 : 100 }}>
                        <img src={fiadIcon} style={{ width: phone ? 24 : 32 }} />

                    </div>

                    <div style={{ width: '75%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <div style={{ display: 'flex', alignItems: 'flex-start', flexDirection: 'column', marginLeft: 16 }}>
                            <Typography style={{ fontSize: phone ? 14 : 18, fontWeight: '500', textTransform: 'none', fontFamily: "Avenir", }}>{t('cad')}</Typography>
                            <Avatar style={{ width: phone ? 14 : 20, height: phone ? 14 : 20, alignSelf: 'center', marginRight: 8, marginTop: 8 }} src={getIcons('CAD')} variant="square" />

                        </div>
                        <img src={enterIcon} style={{ width: phone ? 24 : 32, marginRight: 16 }} />

                    </div>
                </Button>


                {/* <div style={{ display: 'flex', flexDirection: 'column', marginBottom: 40 }}>
                    <Button
                        className={phone ? `${classes.buttonPhone} ${classes.nextPhone}` : `${classes.button} ${classes.next}`}
                        style={{ margin: phone ? '8px 0' : '15px 0', opacity: fundActionLoading || !loaded || !canGoToInput ? 0.3 : 1 }}
                        disabled={fundActionLoading || !loaded || !canGoToInput}
                        onClick={() => { withdrawType === 'Fiat' ? goToInput() : goToWithdrawCrypto() }}
                    >
                        {t('next')}
                    </Button>

                </div> */}

                <div style={{ display: 'flex', flexDirection: 'column', height: 100 }} />
            </div>
        )
    }

    const renderWithdrawCrypto = () => {
        // setter
        // localStorage.setItem('showCryptoWarning', true);
        // getter
        // const showCryptoWarning = localStorage.getItem('showCryptoWarning');

        return (
            <div>
                <div style={{ marginTop: phone ? 16 : 60, display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                        <Typography
                            style={
                                phone ? { color: "#1C2237", fontFamily: "Avenir", fontSize: 16, fontWeight: 500, letterSpacing: 0, lineHeight: '22px', textAlign: 'initial' }
                                    : { color: "#1C2237", fontFamily: "Avenir", fontSize: 24, fontWeight: 500, letterSpacing: 0, lineHeight: '35px', textAlign: 'initial' }
                            }
                        >
                            {withdrawType === 'Fiat' ? t('withdrawFiatTitle') : t('withdrawCryptoTitle')}
                        </Typography>
                        <Typography style={{ color: '#858E9D', fontSize: phone ? 12 : 14, fontWeight: '500', fontFamily: "Avenir", }} >
                            {t('withdrawCoinSub1')}
                        </Typography>
                    </div>
                    <Button

                        style={{ padding: 4 }}
                        disabled={fundActionLoading || !loaded}
                        onClick={() => { goBackSelectWithdrawType() }}
                    >
                        <img src={backToIcon} style={{ width: phone ? 24 : 32, marginRight: 4 }} />
                    </Button>
                </div>
                <div style={{ height: 1, width: '100%', backgroundColor: '#EFEFEF', marginTop: 12, marginBottom: 36 }} />
                <div style={{ marginBottom: 40, marginTop: phone ? 18 : 24, justifyContent: 'space-between', display: 'flex', flexDirection: 'column' }}>
                    <div style={{ width: '100%' }}>
                        <Typography
                            className={phone ? classes.inputLabelPhone : classes.inputLabel}
                        >
                            {t('coin')}
                        </Typography>
                        <div
                            style={{ cursor: 'pointer', color: 'black' }}
                            className={classes.cryptoSelect}
                            onClick={openCryptoModal}
                        >
                            {/* <Avatar style={{ width: 22, height: 21, borderRadius: 5, alignSelf: 'center' }} src={getIcons('USDT')} variant="square" /> */}
                            <img src={getIcons(coin)} style={{ height: 22, width: 22, marginRight: 4 }} />
                            <Typography style={{ fontSize: phone ? 12 : 16, fontWeight: 500, padding: 10 }}>{coin}</Typography>
                            <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
                                <Avatar style={{ width: 14, height: 7, borderRadius: 5, alignSelf: 'center' }} src={downArrow} variant="square" />
                            </div>
                        </div>
                    </div>
                    <div style={{ width: '100%', marginTop: 40 }}>
                        <Typography
                            className={phone ? classes.inputLabelPhone : classes.inputLabel}
                        >
                            {t('network')}
                        </Typography>
                        <div
                            style={{ cursor: 'pointer', color: 'black' }}
                            className={classes.cryptoSelect}
                            onClick={openNetworkModal}
                        >
                            <Typography style={{ fontSize: phone ? 12 : 16, fontWeight: 500, padding: 10 }}>{chain === 'TRX' ? 'TRC20' : chain}</Typography>
                            <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
                                <Avatar style={{ width: 14, height: 7, borderRadius: 5, alignSelf: 'center' }} src={downArrow} variant="square" />
                            </div>
                        </div>
                    </div>
                    <div style={{ width: '100%', marginTop: 40 }}>
                        <Typography
                            className={phone ? classes.inputLabelPhone : classes.inputLabel}
                        >
                            {t('address')}
                        </Typography>
                        <InputBase
                            className={!phone ? classes.input : classes.inputPhone}
                            placeholder={t('placeHolderAddress')}
                            value={address}
                            style={{ color: 'black', height: 50 }}
                            onChange={handleAddressChange}
                            // onFocus={handleWithdrawAmountFocus}
                            // onBlur={handleWithdrawAmountBlur}
                            disabled={fundActionLoading || !loaded}
                        />
                        {
                            validAddress || address === '' ? null :
                                <Typography style={{ marginTop: 8, fontFamily: "Avenir", color: "#DC3055", fontSize: phone ? 12 : 14, fontWeight: 500, lineHeight: phone ? "11px" : '16px', textAlign: "left" }}>
                                    {t('invalidAddress')}
                                </Typography>
                        }
                    </div>
                    <div style={{ width: '100%', marginTop: 40 }}>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <Typography
                                className={phone ? classes.inputLabelPhone : classes.inputLabel}
                            >
                                {t('withdrawAmount')}
                            </Typography>
                            <Typography
                                className={phone ? classes.inputLabelPhone : classes.inputLabel}
                                style={{ alignSelf: 'flex-end', color: '#9097A1' }}
                            >
                                {`${t('balance')}: ${roundingDown(coinInfo.amount, 8)} ${coinInfo.token}`}
                            </Typography>
                        </div>
                        <InputBase
                            className={!phone ? classes.input : classes.inputPhone}
                            placeholder={'0.00'}
                            value={cryptoWithdrawAmount}
                            style={{ color: 'black', height: 50 }}
                            onChange={handleCryptoWithdrawAmountChange}
                            onFocus={handleCryptoWithdrawAmountFocus}
                            onBlur={handleCryptoWithdrawAmountBlur}
                            startAdornment={
                                <InputAdornment position="start">
                                    {/* <Avatar style={{ marginRight: 5, width: 24, height: 24, borderRadius: 5, alignSelf: 'center' }} src={getIcons(coin)} variant="square" /> */}

                                    <img src={getIcons(coin)} style={{ width: 22, marginRight: 4 }} />

                                </InputAdornment>
                            }
                            endAdornment={
                                <InputAdornment position="end">
                                    {
                                        currency === '' ?
                                            null :
                                            <Button
                                                className={phone ? `${classes.buttonPhone}` : `${classes.button}`}
                                                style={{ backgroundColor: 'transparent', color: '#0173F9', margin: phone ? '8px 0' : '15px 0', opacity: fundActionLoading || !loaded || currency === '' ? 0.3 : 1 }}
                                                disabled={fundActionLoading || !loaded || currency === ''}
                                                onClick={() => { allIn() }}
                                            >
                                                {t('max')}
                                            </Button>
                                    }
                                </InputAdornment>
                            }
                            disabled={fundActionLoading || !loaded}
                        />
                        {
                            inputStatus === 2 ? <Typography style={{ marginTop: 8, fontFamily: "Avenir", color: "#DC3055", fontSize: phone ? 12 : 14, fontWeight: 500, lineHeight: phone ? "11px" : '16px', textAlign: "left" }}>

                                {t(inputMsg)}
                            </Typography> : null

                        }
                        {
                            coin === '' || chain === '' ?
                                null : <div style={{ marginTop: 24, marginBottom: 10 }}>
                                    <ul className={classes.withdrawNote}>
                                        <li className={classes.withdrawNoteText}><Typography className={classes.withdrawNoteTitle}>{`* ${t('withdrawFee')} `}</Typography>{`${feeInfo.amount === undefined ? '--' : roundingDown(feeInfo.amount, 4)} ${feeInfo.base}`}</li>
                                        <li className={classes.withdrawNoteText}><Typography className={classes.withdrawNoteTitle}>{`* ${t('minWithdrawAmount')}`}</Typography>{`${coinInfo.minWithdrawAmount === undefined ? '--' : roundingDown(coinInfo.minWithdrawAmount, 4)} ${coin}`}</li>
                                        <li className={classes.withdrawNoteText}><Typography className={classes.withdrawNoteTitle}>{`* ${t('dailyWithdrawLimit')}`}</Typography>{`${coinInfo[`dailyLimit${chain}`] === undefined ? '--' : roundingDown(coinInfo[`dailyLimit${chain}`], 4)} ${coin}`}</li>
                                    </ul>
                                </div>
                        }
                        <div>
                            <Typography style={{ marginTop: phone ? 4 : 8, fontFamily: "Avenir", color: "#1C2237", fontSize: phone ? 12 : 14, fontWeight: 800, lineHeight: phone ? "11px" : '16px', textAlign: "left" }}>
                                {t('withdrawRiskNote')}
                            </Typography>
                        </div>
                        <Button
                            className={phone ? `${classes.buttonPhone} ${classes.nextPhone}` : `${classes.button} ${classes.next}`}
                            style={{ margin: phone ? '8px 0' : '15px 0', opacity: fundActionLoading || !loaded || !canGoToConfirmingCrypto ? 0.3 : 1,width:'100%' }}
                            disabled={fundActionLoading || !loaded || !canGoToConfirmingCrypto}
                            onClick={() => { goToConfirmingCrypto() }}
                        >
                            {t('next')}
                        </Button>
                        {/* <Button
                            className={phone?`${classes.buttonPhone} ${classes.backPhone}`:`${classes.button} ${classes.back}`}
                            style={{margin:phone ? '8px 0':'15px 0', opacity: fundActionLoading || !loaded ? 0.3 : 1}}
                            disabled={fundActionLoading || !loaded}
                            onClick={()=> {goBackSelectWithdrawType()}}
                        >
                            <ArrowBackIosIcon style={{fill: '#1C2237'}} />{t('back')}
                        </Button> */}
                    </div>
                </div>

            </div>
        )
    }

    const renderConfirmingCrypto = () => {

        return (
            <div>

                {/*

                <div style={{ marginTop: phone ? 16 : 60, display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>


                    <Typography
                        style={
                            phone ? { color: "#1C2237", fontFamily: "Avenir", fontSize: 16, fontWeight: 500, letterSpacing: 0, lineHeight: '22px', textAlign: 'initial' }
                                : { color: "#1C2237", fontFamily: "Avenir", fontSize: 24, fontWeight: 500, letterSpacing: 0, lineHeight: '35px', textAlign: 'initial' }
                        }
                    >
                        {t("confirmWithdraw")}
                    </Typography>

                    <Button

                        style={{ opacity: fundActionLoading || !loaded ? 0.3 : 1, backgroundColor: '#F9F9F9', borderRadius: 8, textTransform: 'none' }}
                        // onClick={() => { goBack() }}
                        disabled={fundActionLoading || !loaded}

                        onClick={() => { backToWithdrawCrypto() }}

                    >
                        <Typography style={{ fontSize: phone ? 10 : 14, fontWeight: '500' }}>
                            {t('back')}
                        </Typography>

                    </Button>


                </div> */}
                <div style={{ marginTop: phone ? 16 : 60, display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                        <Typography
                            style={
                                phone ? { color: "#1C2237", fontFamily: "Avenir", fontSize: 16, fontWeight: 500, letterSpacing: 0, lineHeight: '22px', textAlign: 'initial' }
                                    : { color: "#1C2237", fontFamily: "Avenir", fontSize: 24, fontWeight: 500, letterSpacing: 0, lineHeight: '35px', textAlign: 'initial' }
                            }
                        >
                            {t("confirmWithdraw")}
                        </Typography>
                        {/* <Typography style={{ color: '#858E9D', fontSize: phone ? 12 : 14, fontWeight: '400' }} >
                            {t("confirmWithdraw")}
                        </Typography> */}
                    </div>
                    <Button

                        style={{ padding: 4 }}
                        disabled={fundActionLoading || !loaded}
                        onClick={() => { backToWithdrawCrypto() }}
                    >

                        <img src={backToIcon} style={{ width: phone ? 24 : 32, marginRight: 4 }} />


                    </Button>
                </div>
                <div style={{ height: 1, width: '100%', backgroundColor: '#EFEFEF', marginTop: 12, marginBottom: 36 }} />
                <div style={{ marginTop: phone ? 12 : 16, justifyContent: 'space-between', display: 'flex', flexDirection: 'column' }}>
                    <div >
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }} >
                            <Typography style={{
                                // padding: phone ? '0 14px' : '0 15px',
                                fontWeight: '500',
                                color: '#9097A1',
                                fontSize: phone ? 12 : 14,
                            }}>
                                {t('receiveAmount')}
                            </Typography>
                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>

                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start' }}>
                                    <Avatar style={{ width: phone ? 14 : 18, height: phone ? 14 : 18, alignSelf: 'center' }} src={getIcons(coin)} variant="square" />
                                    <Typography style={{
                                        // padding: phone ? '0 14px' : '0 15px',
                                        fontWeight: '500',
                                        marginLeft: 8,
                                        marginRight: 8,
                                        color: '#1C2237',
                                        fontSize: phone ? 12 : 16,
                                        // maxWidth: phone ? 209 : 'unset',
                                        wordBreak: 'break-word',
                                        textAlign: 'left'
                                    }}>{`${feeCoin === coin ? roundingDown(cryptoWithdrawAmount - feeInfo.amount, 8) : roundingDown(cryptoWithdrawAmount, 8)} ${coin} `}</Typography>
                                    <Typography style={{
                                        // padding: phone ? '0 14px' : 'unset',
                                        fontWeight: 500,
                                        color: '#9097A1',
                                        fontSize: phone ? 10 : 12,
                                        maxWidth: phone ? 209 : 'unset',
                                        wordBreak: 'break-word',
                                        textAlign: 'left'
                                    }}>
                                        {`(${t('withdrawFee')} ${feeInfo.amount} ${feeCoin})`}
                                    </Typography>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style={{ width: '100%', marginTop: 40 }} >
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                                <Typography style={{
                                    fontWeight: '500',
                                    color: '#9097A1',
                                    fontSize: phone ? 12 : 14,
                                }}>{t('withdrawAddress')}</Typography>

                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <Typography style={{
                                    fontWeight: '500',
                                    marginRight: 4,
                                    color: 'black',
                                    fontSize: phone ? 12 : 16,
                                    maxWidth: phone ? 209 : 'unset',
                                    wordBreak: 'break-word',
                                    textAlign: 'left'
                                }}>{address}</Typography>
                                <Typography style={{ fontSize: phone ? 10 : 12, borderRadius: 5, color: 'white', fontWeight: 500, backgroundColor: '#0173F9', paddingLeft: 4, paddingRight: 4 }}>
                                    {chain}
                                </Typography>
                            </div>
                        </div>
                    </div>
                    <div style={{ width: '100%', marginTop: 40, marginBottom: 40 }} >
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <Typography
                                style={
                                    phone ? { width: '100%', marginTop: 5, fontFamily: "Avenir", fontSize: 12, color: '#858E9D', fontWeight: 500, letterSpacing: 0, lineHeight: '22px', textAlign: 'initial' }
                                        : { width: '100%', marginTop: 10, fontFamily: "Avenir", fontSize: 14, color: '#858E9D', fontWeight: 500, letterSpacing: 0, lineHeight: '35px', textAlign: 'initial' }
                                }
                            >
                                {t("emailCode")}
                            </Typography>
                            <Typography
                                style={
                                    phone ? { width: '100%', marginTop: 5, color: "#9097A1", fontStyle: 'oblique', fontFamily: "Avenir", fontSize: 12, fontWeight: 500, letterSpacing: 0, lineHeight: '22px', textAlign: 'end' }
                                        : { width: '100%', marginTop: 10, color: "#9097A1", fontStyle: 'oblique', fontFamily: "Avenir", fontSize: 14, fontWeight: 500, letterSpacing: 0, lineHeight: '35px', textAlign: 'end' }
                                }
                            >
                                {`${userInfo.username.substring(0, 4)}****${userInfo.username.split('@')[1]}`}
                            </Typography>
                        </div>
                        <InputBase
                            id="filled-adornment-phone"
                            style={
                                phone ? { borderRadius: 12, backgroundColor: 'white', width: '100%', height: '50px', fontSize: 14, padding: 14, fontWeight: 500, border: "1px solid #EDEDED" }
                                    : { borderRadius: 16, backgroundColor: 'white', width: '100%', height: '55px', fontSize: 16, padding: 20, fontWeight: 500, border: "1px solid #EDEDED" }
                            }
                            value={emailCode}
                            placeholder={t('placeHolderVerificationCode')}
                            onChange={(e) => { handleEmailCodeChange(e) }}
                            endAdornment={
                                <InputAdornment position="end">
                                    <Button
                                        className={classes.font}
                                        style={{ color: "#0173F9", fontSize: phone ? 14 : 16, textTransform: "none", minWidth: 20 }}
                                        onClick={sendEmailCode}
                                        disabled={startEmailCounting}
                                    >
                                        {startEmailCounting ? emailCounter : t('sendCode')}
                                    </Button>
                                </InputAdornment>
                            }
                        />
                        {
                            emailCodeStatus === 0 ? null :
                                <Typography style={{ marginTop: phone ? 4 : 8, fontFamily: "Avenir", color: "#DC3055", fontSize: phone ? 12 : 14, fontWeight: 500, lineHeight: phone ? "11px" : '16px', textAlign: "left" }}>
                                    {t(localEmailCodeMsg)}
                                </Typography>
                        }

                        <div style={{ display: 'flex', flexDirection: 'row', marginTop: 20 }}>
                            <Typography
                                style={
                                    phone ? { width: '100%', marginTop: 5, fontFamily: "Avenir", fontSize: 12, fontWeight: 500, letterSpacing: 0, lineHeight: '22px', color: '#858E9D', textAlign: 'initial' }
                                        : { width: '100%', marginTop: 10, fontFamily: "Avenir", fontSize: 14, fontWeight: 500, letterSpacing: 0, lineHeight: '35px', color: '#858E9D', textAlign: 'initial' }
                                }
                            >
                                {t("mobileCode")}
                            </Typography>
                            <Typography
                                style={
                                    phone ? { width: '100%', marginTop: 5, color: "#9097A1", fontStyle: 'oblique', fontFamily: "Avenir", fontSize: 12, fontWeight: 500, letterSpacing: 0, lineHeight: '22px', textAlign: 'end' }
                                        : { width: '100%', marginTop: 10, color: "#9097A1", fontStyle: 'oblique', fontFamily: "Avenir", fontSize: 14, fontWeight: 500, letterSpacing: 0, lineHeight: '35px', textAlign: 'end' }
                                }
                            >
                                {`${userInfo.verificationPhone.substring(0, 3)}****${userInfo.verificationPhone.substring(userInfo.verificationPhone.length - 3, userInfo.verificationPhone.length)}`}
                            </Typography>
                        </div>
                        <InputBase
                            id="filled-adornment-phone"
                            style={
                                phone ? { borderRadius: 12, backgroundColor: 'white', width: '100%', height: '50px', fontSize: 14, padding: 14, fontWeight: 500, border: "1px solid #EDEDED" }
                                    : { borderRadius: 16, backgroundColor: 'white', width: '100%', height: '55px', fontSize: 16, padding: 20, fontWeight: 500, border: "1px solid #EDEDED" }
                            }
                            placeholder={t('placeHolderVerificationCode')}
                            value={verificationCode}
                            onChange={(e) => { handleVerificationCodeChange(e) }}
                            endAdornment={
                                <InputAdornment position="end">
                                    <Button
                                        className={classes.font}
                                        style={{ color: "#0173F9", fontSize: phone ? 14 : 16, textTransform: "none", minWidth: 20 }}
                                        onClick={sendVerificationCode}
                                        disabled={startCounting}
                                    >
                                        {startCounting ? counter : t('sendCode')}
                                    </Button>
                                </InputAdornment>
                            }
                        />
                        {
                            phoneCodeStatus === 0 ? null :
                                <Typography style={{ marginTop: phone ? 4 : 8, fontFamily: "Avenir", color: "#DC3055", fontSize: phone ? 12 : 14, fontWeight: 500, lineHeight: phone ? "11px" : '16px', textAlign: "left" }}>
                                    {t(phoneCodeMsg)}
                                </Typography>
                        }
                        {
                            localCryptoWithdrawMsg === '' ? null :
                                <Typography style={{ marginTop: phone ? 4 : 8, fontFamily: "Avenir", color: "#DC3055", fontSize: phone ? 12 : 14, fontWeight: 500, lineHeight: phone ? "11px" : '16px', textAlign: "left" }}>
                                    {t(localCryptoWithdrawMsg)}
                                </Typography>
                        }
                    </div>
                </div>
                <div>
                    <Typography style={{ marginTop: phone ? 4 : 8, fontFamily: "Avenir", color: "#1C2237", fontSize: phone ? 12 : 14, fontWeight: 800, lineHeight: phone ? "11px" : '16px', textAlign: "left" }}>
                        {t('withdrawRiskNote')}
                    </Typography>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', marginBottom: 40 }}>
                    <Button
                        className={phone ? `${classes.buttonPhone} ${classes.nextPhone}` : `${classes.button} ${classes.next}`}
                        style={{ margin: phone ? '8px 0' : '15px 0', opacity: fundActionLoading || !loaded || !canWithdrawCrypto ? 0.3 : 1 }}
                        disabled={fundActionLoading || !loaded || !canWithdrawCrypto}
                        onClick={() => { withdrawCrypto() }}
                    >
                        {t('withdraw')}
                    </Button>
                    {/* <Button
                        className={phone?`${classes.buttonPhone} ${classes.backPhone}`:`${classes.button} ${classes.back}`}
                        style={{margin:phone ? '8px 0':'15px 0', opacity: fundActionLoading || !loaded ? 0.3 : 1}}
                        disabled={fundActionLoading || !loaded}
                        onClick={()=> {backToWithdrawCrypto()}}
                    >
                        <ArrowBackIosIcon style={{fill: '#1C2237'}} />{t('back')}
                    </Button> */}
                </div>
            </div>
        )
    }

    const renderInputting = () => {
        return (
            <div>
                {/* <div style={{ marginTop: phone ? 16 : 60, display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>


                    <Typography
                        style={
                            phone ? { color: "#1C2237", fontFamily: "Avenir", fontSize: 16, fontWeight: 500, letterSpacing: 0, lineHeight: '22px', textAlign: 'initial' }
                                : { color: "#1C2237", fontFamily: "Avenir", fontSize: 24, fontWeight: 500, letterSpacing: 0, lineHeight: '35px', textAlign: 'initial' }
                        }
                    >
                        {withdrawType === 'Fiat' ? t('withdrawFiatTitle') : t('withdrawCryptoTitle')}
                    </Typography>

                    <Button

                        style={{ opacity: fundActionLoading || !loaded ? 0.3 : 1, backgroundColor: '#F9F9F9', borderRadius: 8, textTransform: 'none' }}
                        disabled={fundActionLoading || !loaded}
                        // onClick={() => { goBack() }}

                        onClick={() => { goBackSelectWithdrawType() }}

                    >
                        <Typography style={{ fontSize: phone ? 10 : 14, fontWeight: '500' }}>
                            {t('back')}
                        </Typography>

                    </Button>
                </div> */}

                <div style={{ marginTop: phone ? 16 : 60, display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <Typography
                            style={
                                phone ? { color: "#1C2237", fontFamily: "Avenir", fontSize: 16, fontWeight: 500, letterSpacing: 0, lineHeight: '22px', textAlign: 'initial' }
                                    : { color: "#1C2237", fontFamily: "Avenir", fontSize: 24, fontWeight: 500, letterSpacing: 0, lineHeight: '35px', textAlign: 'initial' }
                            }
                        >
                            {t('withdrawFiatTitle')}
                        </Typography>
                        <Typography style={{ color: '#858E9D', fontSize: phone ? 12 : 14, fontWeight: '500', fontFamily: "Avenir", }} >
                            {t('withdrawCoinSub2')}
                        </Typography>
                    </div>
                    <Button

                        style={{ padding: 4 }}
                        disabled={fundActionLoading || !loaded}
                        onClick={() => { goBackSelectWithdrawType() }}
                    >
                        <img src={backToIcon} style={{ width: phone ? 24 : 32, marginRight: 4 }} />


                    </Button>
                </div>
                <div style={{ height: 1, width: '100%', backgroundColor: '#EFEFEF', marginTop: 12, marginBottom: 36 }} />
                <div style={{ marginTop: phone ? 18 : 40, justifyContent: 'space-between', display: 'flex', flexDirection: 'column' }}>
                    <div style={{ width: '100%' }}>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <Typography
                                className={phone ? classes.inputLabelPhone : classes.inputLabel}
                                style={{ alignSelf: 'flex-start' }}
                            >
                                {t('withdrawAmount')}
                            </Typography>
                            {
                                balance.amount > 0 ?
                                    <Typography
                                        className={phone ? classes.inputLabelPhone : classes.inputLabel}
                                        style={{ alignSelf: 'flex-end', color: '#9097A1' }}
                                    >
                                        {`${t('balance')}: ${roundingDown(balance.amount, 2)} ${balance.symbol}`}
                                    </Typography> : null
                            }
                        </div>
                        <InputBase
                            className={!phone ? classes.input : classes.inputPhone}
                            placeholder={'0.00'}
                            value={withdrawAmount === 0 ? '' : withdrawAmount.toString()}
                            style={{ color: 'black', height: 50 }}
                            onChange={handleWithdrawAmountChange}
                            onFocus={handleWithdrawAmountFocus}
                            onBlur={handleWithdrawAmountBlur}
                            endAdornment={
                                <InputAdornment position="end">
                                    {
                                        currency === '' ?
                                            null :
                                            <Button
                                                style={{ display: "flex", justifyContent: 'flex-end', alignItems: 'center', width: 80, height: 40, opacity: fundActionLoading || !loaded || currency === '' ? 0.3 : 1 }}
                                                // disabled={fundActionLoading || !loaded || currency === ''}
                                                onClick={() => { allIn() }}
                                            >
                                                <Typography style={{ fontSize: phone ? 12 : 14, fontWeight: '500', color: '#0173F9' }}>
                                                    {t('max')}
                                                </Typography>
                                            </Button>
                                    }

                                </InputAdornment>
                            }
                            startAdornment={
                                <InputAdornment position="start">
                                    <Select
                                        value={currency}
                                        onChange={(e) => handleSelectChange(e)}
                                        displayEmpty
                                        disableUnderline
                                        className={classes.select}

                                        inputProps={{
                                            'aria-label': 'Without label', classes: {
                                                icon: classes.icon
                                            }
                                        }}
                                        IconComponent={(props) => <div style={{ marginLeft: -24, display: 'flex', alignItems: 'center', justifyContent: 'space-between', borderRight: '1px solid #D8D8D8', height: 30 }}>
                                            <img style={{ width: 16, height: 16, marginRight: 12 }} src={dropdownIcon} />
                                        </div>}


                                        MenuProps={{
                                            classes: {
                                                paper: classes.menu
                                            }
                                        }}
                                        onClose={onSelectClose}
                                        onOpen={onSelectOpen}
                                    >
                                        <MenuItem value="" disabled>

                                            <em style={{ fontStyle: 'normal' }}>{t('currency')}</em>
                                        </MenuItem>
                                        <MenuItem value={'CAD'}>
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <Avatar style={{ marginRight: 5, width: phone ? 14 : 18, height: phone ? 14 : 18, alignSelf: 'center', marginBottom: 2 }} src={getIcons('CAD')} variant="square" />
                                                <Typography style={{ fontSize: phone ? 12 : 16, color: '#1C2237', fontWeight: '500' }}>CAD</Typography>
                                            </div>
                                        </MenuItem>
                                        {/*<MenuItem value={'USD'}>*/}
                                        {/*    <div style={{ display: 'flex', alignItems: 'center' }}>*/}
                                        {/*        <Avatar style={{ marginRight: 5, width: 24, height: 24, borderRadius: 5, alignSelf: 'center' }} src={getIcons('USD')} variant="square" />*/}
                                        {/*        <Typography style={{ fontSize: phone ? 12 : 16 }}>USD</Typography>*/}
                                        {/*    </div>*/}
                                        {/*</MenuItem>*/}
                                    </Select>
                                </InputAdornment>
                            }
                            disabled={fundActionLoading || !loaded}
                        />
                        <div style={{ display: 'flex', alignItems: 'center', marginTop: 8, height: 24, opacity: inputMsg === '' ? 0 : 1 }}>
                            {/* <img src={warningLabel} style={{ width: 16, marginRight: 4 }} /> */}
                            <Typography style={{ fontFamily: "Avenir", color: "#DC3055", fontSize: phone ? 12 : 14, fontWeight: 500, lineHeight: phone ? "11px" : '16px', textAlign: "left" }}>
                                {t(inputMsg)}
                            </Typography>
                        </div>



                    </div>
                    <div style={{ width: '100%', marginTop: 40 }}>

                        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 12 }}>
                            <Typography style={{ fontSize: phone ? 12 : 14, color: '#8B96A7', fontWeight: '500', fontFamily: 'Avenir' }}>  {t('paymentMethod')}</Typography>
                            <Button style={{
                                display: 'flex', alignItems: 'center', flexDirection: 'column',
                                padding: 0,
                                minHeight: 0,
                                minWidth: 0,
                            }}

                                onClick={() => { window.open(i18n.language === 'en' ? "https://otcdocs.yesbit.io/user-guide/withdraw/withdraw-fiat-currency" : "https://otcdocs.yesbit.io/v/jian-ti-zhong-wen/user-guide/withdraw/fiat") }}
                            >
                                <img src={questionIcon} style={{ height: phone ? 14 : 18, marginRight: 4 }} />
                                <Typography style={{ fontSize: phone ? 12 : 14, color: '#8B96A7', fontWeight: '500', fontFamily: 'Avenir' }}>{t('withdrawHelpButton')}</Typography>

                            </Button>

                        </div>
                        <List style={{ width: '100%' }} >
                            {paymentMethod.map(item => (
                                <ListItem key={item.title} style={{
                                    backgroundColor: '#F1F0F0',
                                    marginBottom: 24, borderRadius: 12, padding: '15px 15px',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'space-between'
                                }}
                                >
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <img src={item.icon} style={{ width: phone ? 16 : 32, marginRight: 16 }} />

                                        <div style={{ marginRight: 5 }}>
                                            <Typography

                                                style={{ color: 'black', fontSize: phone ? 12 : 14, fontWeight: '500' }}
                                            >
                                                {item.title}
                                            </Typography>

                                            <Typography style={{ color: '#858E9D', fontSize: phone ? 10 : 12, fontWeight: '500' }}>
                                                {item.subTitle}
                                            </Typography>
                                        </div>
                                    </div>
                                    <div style={{ justifyContent: 'flex-end', alignItems: 'flex-end', display: 'flex', flexDirection: 'column' }}>
                                        <Typography style={{ color: withdrawAmount >= item.min && withdrawAmount <= item.max ? "black" : '#858E9D', fontSize: phone ? 14 : 16, fontWeight: '500' }}>
                                            {item.range}
                                        </Typography>

                                    </div>
                                    {
                                        withdrawAmount >= item.min && withdrawAmount <= item.max  ?
                                            <div style={{ height: phone ? 18 : 24, borderRadius: 14, backgroundColor: '#0173F9', position: 'absolute', right: 12, bottom: -10, display: 'flex', alignItems: 'center' }}>
                                                <Avatar style={{ width: phone ? 12 : 16, height: phone ? 12 : 16, alignSelf: 'center', marginLeft: 4 }} src={checkIcon} variant="square" />
                                                <Typography style={{ color: 'white', fontSize: phone ? 10 : 12, fontWeight: '500', marginRight: 8, marginLeft: 4 }}>

                                                    {t('selected')}
                                                </Typography>
                                            </div> : null

                                    }


                                </ListItem>
                            )
                            )}
                        </List>
                    </div>
                    {
                        localWithdrawMsg !== '' ? <Typography
                            style={
                                phone ? { marginTop: 10, color: "red", fontFamily: "Avenir", fontSize: 12, fontWeight: 500, textAlign: 'initial' }
                                    : { marginTop: 10, color: "red", fontFamily: "Avenir", fontSize: 14, fontWeight: 500, textAlign: 'initial' }
                            }
                        >
                            {t(localWithdrawMsg)}
                        </Typography> : null

                    }
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', marginBottom: 40 }}>
                    <Button
                        className={phone ? `${classes.buttonPhone} ${classes.nextPhone}` : `${classes.button} ${classes.next}`}
                        style={{ margin: phone ? '8px 0' : '15px 0', opacity: fundActionLoading || !loaded || !canGoToConfirming ? 0.3 : 1 }}
                        disabled={fundActionLoading || !loaded || !canGoToConfirming}
                        onClick={() => { eTransferring ? goToConfirming() : submit() }}
                    >
                        {t('next')}
                    </Button>
                </div>
            </div>
        )
    }

    const renderConfirming = () => {
        return (
            <div>
                <div style={{ marginTop: phone ? 16 : 60, display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <Typography
                            style={
                                phone ? { color: "#1C2237", fontFamily: "Avenir", fontSize: 16, fontWeight: 500, letterSpacing: 0, lineHeight: '22px', textAlign: 'initial' }
                                    : { color: "#1C2237", fontFamily: "Avenir", fontSize: 24, fontWeight: 500, letterSpacing: 0, lineHeight: '35px', textAlign: 'initial' }
                            }
                        >
                            {t("confirmWithdraw")}
                        </Typography>
                        {/* <Typography style={{ color: '#858E9D', fontSize: phone ? 12 : 14, fontWeight: '400' }} >
                            {t('withdrawCoinSub2')}
                        </Typography> */}
                    </div>
                    <Button

                        style={{ padding: 4 }}
                        disabled={fundActionLoading || !loaded}
                        onClick={() => { backToInput() }}
                    >
                        <img src={backToIcon} style={{ width: phone ? 24 : 32, marginRight: 4 }} />


                    </Button>
                </div>
                <div style={{ height: 1, width: '100%', backgroundColor: '#EFEFEF', marginTop: 12, marginBottom: phone ? 12 : 36 }} />
                <Typography
                    style={
                        phone ? { color: "#858E9D", fontFamily: "Avenir", fontSize: 12, fontWeight: 500, textAlign: 'initial' }
                            : { color: "#858E9D", fontFamily: "Avenir", fontSize: 14, fontWeight: 500, textAlign: 'initial' }
                    }
                >
                    {t("confirmWithdrawContent")}
                </Typography>
                <Typography
                    style={
                        phone ? { marginTop: 10, color: "#0173F9", fontFamily: "Avenir", fontSize: 12, fontWeight: 500, textAlign: 'initial' }
                            : { marginTop: 10, color: "#0173F9", fontFamily: "Avenir", fontSize: 14, fontWeight: 500, textAlign: 'initial' }
                    }
                >
                    {t("confirmWithdrawContent2")}
                </Typography>
                <Typography
                    style={
                        phone ? { marginTop: 10, color: "#0173F9", fontFamily: "Avenir", fontSize: 12, fontWeight: 500, textAlign: 'initial' }
                            : { marginTop: 10, color: "#0173F9", fontFamily: "Avenir", fontSize: 14, fontWeight: 500, textAlign: 'initial' }
                    }
                >
                    {t("confirmWithdrawContent3")}
                </Typography>
                <Typography
                    style={
                        phone ? { marginTop: 10, color: "#858E9D", fontFamily: "Avenir", fontSize: 12, fontWeight: 500, textAlign: 'initial' }
                            : { marginTop: 10, color: "#858E9D", fontFamily: "Avenir", fontSize: 14, fontWeight: 500, textAlign: 'initial' }
                    }
                >
                    {t("confirmWithdrawContent4")}
                </Typography>
                <div style={{ marginTop: phone ? 18 : 24, justifyContent: 'space-between', display: 'flex', flexDirection: 'column' }}>
                    {/* <div >
                        <TextField
                            label={t('receive')}
                            defaultValue={`${withdrawAmount} ${currency}`}
                            InputProps={{
                                readOnly: true,
                                disableUnderline: true,
                                style: {
                                    padding: phone ? '0 14px' : '0 15px',
                                    fontWeight: '500',
                                    color: 'black',
                                    fontSize: phone ? 12 : 18
                                },
                                endAdornment: currency === '' ? null : <Avatar style={{ marginRight: 5, width: phone ? 16 : 24, height: phone ? 16 : 24, borderRadius: 5, alignSelf: 'center' }} src={getIcons(currency)} variant="square" />
                            }}
                            InputLabelProps={{
                                style: {
                                    padding: phone ? '12px 20px' : 20,
                                    fontSize: 18,
                                    fontWeight: '500',
                                    color: '#9097A1'
                                },
                            }}
                            className={phone ? classes.confirmingPhone : classes.confirming}
                            style={{ color: 'black' }}
                        />
                    </div> */}

                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <Typography style={{
                            fontWeight: '500',
                            marginRight: 4,
                            color: 'black',
                            fontSize: phone ? 12 : 16,
                            maxWidth: phone ? 209 : 'unset',
                            wordBreak: 'break-word',
                            textAlign: 'left'
                        }}>{t('receive')}</Typography>
                        <div style={{ display: 'flex' }}>
                            <Avatar style={{ marginRight: 5, width: phone ? 16 : 24, height: phone ? 16 : 24, borderRadius: 5, alignSelf: 'center' }} src={getIcons(currency)} variant="square" />
                            <Typography style={{ fontSize: phone ? 12 : 16, color: 'black', fontWeight: 500, paddingLeft: 4, paddingRight: 4 }}>
                                {withdrawAmount} {currency}
                            </Typography>
                        </div>
                    </div>


                    {
                        eTransferring ?
                            <div style={{ width: '100%', marginBottom: 40 }} >
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: phone ? 16 : 24, marginBottom: 40 }}>
                                    <Typography style={{
                                        fontWeight: '500',
                                        marginRight: 4,
                                        color: 'black',
                                        fontSize: phone ? 12 : 16,
                                        maxWidth: phone ? 209 : 'unset',
                                        wordBreak: 'break-word',
                                        textAlign: 'left'
                                    }}>{t("eTransferHead")}</Typography>
                                    <div style={{ display: 'flex' }}>
                                        <Typography style={{ fontSize: phone ? 12 : 16, color: 'black', fontWeight: 500, paddingLeft: 4, paddingRight: 4 }}>
                                            {userInfo.username}
                                        </Typography>
                                    </div>
                                </div>

                                {/* <Typography
                                    style={
                                        phone ? { marginBottom: 5, textAlign: 'initial', fontFamily: 'Avenir', fontSize: 10, fontWeight: 500, letterSpacing: 0, lineHeight: '14px', color: '#858E9D' }
                                            : { marginBottom: 12, textAlign: 'initial', fontFamily: 'Avenir', fontSize: 16, letterSpacing: 0, lineHeight: '22px', fontWeight: '500', color: '#858E9D' }
                                    }
                                >
                                    {t("mobileCode")}
                                </Typography> */}

                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                    <Typography
                                        style={
                                            phone ? { width: '100%', marginTop: 5, fontFamily: "Avenir", fontSize: 12, color: '#858E9D', fontWeight: 500, letterSpacing: 0, lineHeight: '22px', textAlign: 'initial' }
                                                : { width: '100%', marginTop: 10, fontFamily: "Avenir", fontSize: 14, color: '#858E9D', fontWeight: 500, letterSpacing: 0, lineHeight: '35px', textAlign: 'initial' }
                                        }
                                    >
                                        {t("mobileCode")}
                                    </Typography>
                                    <Typography
                                        style={
                                            phone ? { width: '100%', marginTop: 5, color: "#9097A1", fontStyle: 'oblique', fontFamily: "Avenir", fontSize: 12, fontWeight: 500, letterSpacing: 0, lineHeight: '22px', textAlign: 'end' }
                                                : { width: '100%', marginTop: 10, color: "#9097A1", fontStyle: 'oblique', fontFamily: "Avenir", fontSize: 14, fontWeight: 500, letterSpacing: 0, lineHeight: '35px', textAlign: 'end' }
                                        }
                                    >
                                        {`${userInfo.verificationPhone.substring(0, 3)}****${userInfo.verificationPhone.substring(userInfo.verificationPhone.length - 3, userInfo.verificationPhone.length)}`}
                                    </Typography>
                                </div>
                                <InputBase
                                    placeholder={t('placeHolderVerificationCode')}
                                    id="filled-adornment-phone"
                                    style={
                                        phone ? { borderRadius: 12, backgroundColor: 'white', width: '100%', height: '50px', fontSize: 14, padding: 14, fontWeight: 500, border: "1px solid #EDEDED" }
                                            : { borderRadius: 16, backgroundColor: 'white', width: '100%', height: '55px', fontSize: 16, padding: '35px 15px', fontWeight: 500, border: "1px solid #EDEDED" }
                                    }
                                    value={verificationCode}
                                    onChange={(e) => { handleVerificationCodeChange(e) }}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <Button
                                                className={classes.font}
                                                style={{ color: "#0173F9", fontSize: phone ? 14 : 16, textTransform: "none", minWidth: 20 }}
                                                onClick={sendVerificationCode}
                                                disabled={startCounting}
                                            >
                                                {startCounting ? counter : t('sendCode')}
                                            </Button>
                                        </InputAdornment>
                                    }
                                />
                                {
                                    phoneCodeStatus === 0 ? null :
                                        <Typography style={{ marginTop: phone ? 4 : 8, fontFamily: "Avenir", color: "#DC3055", fontSize: phone ? 12 : 14, fontWeight: 500, lineHeight: phone ? "11px" : '16px', textAlign: "left" }}>
                                            {t(phoneCodeMsg)}
                                        </Typography>
                                }
                                {
                                    localWithdrawMsg === '' ? null :
                                        <Typography style={{ marginTop: phone ? 4 : 8, fontFamily: "Avenir", color: "#DC3055", fontSize: phone ? 12 : 14, fontWeight: 500, lineHeight: phone ? "11px" : '16px', textAlign: "left" }}>
                                            {t(localWithdrawMsg)}
                                        </Typography>
                                }
                            </div> : null
                    }
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', marginBottom: 40 }}>
                    <Button
                        className={phone ? `${classes.buttonPhone} ${classes.nextPhone}` : `${classes.button} ${classes.next}`}
                        style={{ opacity: fundActionLoading || !loaded || kycDetails.status !== 'passed' || phoneCodeStatus !== 1 ? 0.3 : 1 }}
                        disabled={fundActionLoading || !loaded || phoneCodeStatus !== 1 || needSendCode}
                        onClick={() => { submit() }}
                    >
                        {t('withdraw')}
                    </Button>
                    {/* <Button
                        className={phone ? `${classes.buttonPhone} ${classes.backPhone}` : `${classes.button} ${classes.back}`}
                        style={{ margin: phone ? '8px 0' : '15px 0', opacity: fundActionLoading || !loaded ? 0.3 : 1 }}
                        disabled={fundActionLoading || !loaded}
                        onClick={() => { backToInput() }}
                    >
                        <ArrowBackIosIcon style={{ fill: '#1C2237' }} />{t('back')}
                    </Button> */}
                </div>
            </div>
        )
    }

    const renderSubmitted = () => {

        return (
            <div style={{ display: 'flex', flexDirection: 'column', height: "80vh" }}>

                <div style={{ display: 'flex', width: '100%', alignItems: 'center', marginTop: 24 }}>
                    <img style={{ width: phone ? 24 : 32, height: phone ? 24 : 32, borderRadius: phone ? 8 : 12, alignSelf: 'center', backgroundColor: '#F9F9F9', padding: phone ? 6 : 12, marginRight: 8 }} src={withdrawType === 'Fiat' ? getIcons(currency) : getIcons(coin)} />

                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', marginLeft: phone ? 4 : 12 }}>
                        <Typography
                            className={classes.submittedTitleText}
                            style={{ fontSize: phone ? 14 : 20, fontWeight: '500', marginTop: 0 }}
                        >
                            {t("withdrawSubmitted")}
                        </Typography>

                    </div>
                </div>

                <div style={{ height: 1, width: '100%', backgroundColor: '#EFEFEF', marginTop: 12, marginBottom: phone ? 12 : 0 }} />

                {/* <Typography
                    style={
                        phone ? { marginTop: 40, color: "#1C2237", fontFamily: "Avenir", fontSize: 16, fontWeight: 500, letterSpacing: 0, lineHeight: '22px', textAlign: 'initial' }
                            : { marginTop: 60, color: "#1C2237", fontFamily: "Avenir", fontSize: 24, fontWeight: 500, letterSpacing: 0, lineHeight: '35px', textAlign: 'initial' }
                    }
                >
                    {t("withdrawSubmitted")}
                </Typography> */}
                {
                    withdrawType === 'Fiat' ?
                        <div style={{ display: 'flex', flexDirection: 'column' }}>

                            <Typography

                                className={classes.submittedTitleText}

                            >

                            </Typography>

                            {eTransferring
                                ?

                                <span
                                    className={classes.submittedTitleText}

                                >   {t("confirmedWithdrawETransfer")}</span>
                                :
                                <span
                                    className={classes.submittedTitleText}

                                >
                                    {t('confirmedContentCSupport1')}
                                    <span style={{ color: '#0173F9' }}>+1 (647) 9476258</span>
                                    <span style={{ color: '#858E9D' }}> {t('confirmedContentCSupport2')}</span>
                                </span>
                            }
                            <div style={{ marginTop: phone ? 18 : 24, justifyContent: 'space-between', display: 'flex', flexDirection: 'column' }}>
                                <List style={{ width: '100%' }} >
                                    <ListItem className={classes.confirmList} >
                                        <ListItemText
                                            className={classes.confirmListTitle}
                                            primary={t('paymentMethod')}
                                        />
                                        <ListItemSecondaryAction>
                                            <Typography className={classes.confirmListText}>{eTransferring ? t('InteracETransfer') : t('CustomerSupportWithdraw')}</Typography>
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                    {
                                        eTransferring ?
                                            <ListItem className={classes.confirmList} >
                                                <ListItemText
                                                    className={classes.confirmListTitle}
                                                    primary={t('eTransferHead')}
                                                />
                                                <ListItemSecondaryAction>
                                                    <Typography className={classes.confirmListText}>{userInfo.username}</Typography>
                                                </ListItemSecondaryAction>
                                            </ListItem> :
                                            <ListItem className={classes.confirmList} >
                                                <ListItemText
                                                    className={classes.confirmListTitle}
                                                    primary={t('phone')}
                                                />
                                                <ListItemSecondaryAction>
                                                    <Typography className={classes.confirmListText}>{userInfo.verificationPhone}</Typography>
                                                </ListItemSecondaryAction>
                                            </ListItem>
                                    }
                                    <ListItem className={classes.confirmList} >
                                        <ListItemText
                                            className={classes.confirmListTitle}
                                            primary={t('totalPayment')}
                                        />
                                        <ListItemSecondaryAction>
                                            <Typography className={classes.confirmListText}>{`$${withdrawAmount} ${currency}`}</Typography>
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                    {/* {
                                        eTransferring ?
                                            <ListItem className={classes.confirmList} >
                                                <ListItemText
                                                    className={classes.confirmListTitle}
                                                    primary={t('validTime')}
                                                />
                                                <ListItemSecondaryAction>
                                                    <Typography style={{ color: '#DC3055' }} className={classes.confirmListText}>{formDateString(validDate.moneyReqExpDate)}</Typography>
                                                </ListItemSecondaryAction>
                                            </ListItem> : null
                                    } */}
                                </List>
                            </div>
                        </div>
                        :
                        <div>
                            <Typography
                                className={classes.submittedTitleText}
                            >
                                {t('cryptoWithdrawSubmitted')}
                            </Typography>
                            <div style={{ marginTop: phone ? 18 : 24, justifyContent: 'space-between', display: 'flex', flexDirection: 'column' }}>
                                <List style={{ width: '100%' }} >
                                    <ListItem className={classes.confirmList} >
                                        <ListItemText
                                            className={classes.confirmListTitle}
                                            primary={t('receiveAmount')}
                                        />
                                        <ListItemSecondaryAction>
                                            <Typography className={classes.confirmListText}>{`${feeCoin === coin ? roundingDown(cryptoWithdrawAmount - feeInfo.amount, 8) : roundingDown(cryptoWithdrawAmount, 8)} ${coin}`}</Typography>
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                    <ListItem className={classes.confirmList} >
                                        <ListItemText
                                            className={classes.confirmListTitle}
                                            primary={t('withdrawFee')}
                                        />
                                        <ListItemSecondaryAction>
                                            <Typography className={classes.confirmListText}>{`${roundingDown(feeInfo.amount, 8)} ${feeCoin}`}</Typography>
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                    <ListItem className={classes.confirmList} >
                                        <ListItemText
                                            className={classes.confirmListTitle}
                                            primary={t('addressWithdrawing')}
                                        />
                                        <ListItemSecondaryAction>
                                            <Typography className={classes.confirmListText}>{`${address.substring(0, 10)}...${address.substring(address.length - 4, address.length)}`}</Typography>
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                    <ListItem className={classes.confirmList} >
                                        <ListItemText
                                            className={classes.confirmListTitle}
                                            primary={t('network')}
                                        />
                                        <ListItemSecondaryAction>
                                            <Typography className={classes.confirmListText} className={classes.confirmListText}>{chain}</Typography>
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                </List>
                            </div>
                        </div>
                }
                <div style={{ display: 'flex', flexDirection: 'column', marginBottom: 40, marginTop: 24 }}>
                    <Button
                        className={phone ? `${classes.buttonPhone} ${classes.nextPhone}` : `${classes.button} ${classes.next}`}
                        style={{ margin: phone ? '8px 0' : '15px 0', opacity: fundActionLoading || !loaded || kycDetails.status !== 'passed' ? 0.3 : 1 }}
                        disabled={fundActionLoading || !loaded}
                        onClick={() => { goBackFromSubmitted() }}
                    >
                        {t('done')}
                    </Button>
                </div>
            </div>
        )
    }

    const cryptoModalBody = (
        <motion.div
            initial={{ opacity: 0, scale: 0 }}
            animate={{ opacity: 1, scale: 1 }}
        >
            <div style={{
                backgroundColor: 'white', borderRadius: 20, width: phone ? " 89vw" : "606px", overflow: 'hidden', outline: 0, display: 'flex', alignItems: 'center', flexDirection: 'column'
            }}>
                <div style={{ width: '91%', marginTop: '7%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography style={{ fontSize: phone ? 16 : 24, fontWeight: "500", color: '#1C2237' }}>
                        {t('selectCoin')}
                    </Typography>
                    <Button
                        // disabled={fundActionLoading || !loaded}
                        onClick={() => { handleClose() }}
                        style={{
                            display: 'inline-block',
                            padding: 0,
                            minHeight: 0,
                            minWidth: 0,
                        }}
                        variant="square"
                    >
                        <img src={closePopIcon} style={{ width: phone ? 32 : 40 }} />


                    </Button>
                </div>
                <div style={{ height: 1, width: '91%', backgroundColor: '#EFEFEF', marginTop: 12, marginBottom: 12 }} />

                <div style={{ justifyContent: 'space-between', display: 'flex', width: "91%" }}>
                    <List style={{ width: '100%' }} >
                        {tokenList.map(item => (
                            <ListItem onClick={() => {
                                if (item.open !== false) {
                                    handleCoinChange(item.token)
                                    setShowCryptoModal(false)
                                }
                            }} key={item.token} style={{ backgroundColor: coin === item.token ? '#F9F9F9' : 'white', marginBottom: 15, borderRadius: phone ? 12 : 20, paddingLeft: phone ? 8 : 20, paddingRight: phone ? 0 : 20, opacity: item.open ? 1 : 0.4 }}
                            >
                                <ListItemAvatar style={{ minWidth: 30 }}>
                                    <img style={{ width: phone ? 20 : 32, height: phone ? 20 : 32, alignSelf: 'center', padding: 4 }} src={getIcons(item.token)} variant="square" />
                                </ListItemAvatar>
                                {/* <ListItemText
                                primary={item.token}
                                // secondary={`${roundingDown(item.amount, 8)} ${item.token}`}
                                className={classes.listItem}
                                style={{ paddingLeft: 15, color: "red", }}
                            />
                             <ListItemText
                                primary={`${roundingDown(item.amount, 8)} ${item.token}`}
                                className={classes.listItem}
                                style={{ paddingLeft: 15, color: "red", }}
                            /> */}
                                <div style={{ marginLeft: phone ? 0 : 8 }}>
                                    <Typography style={{ fontSize: phone ? 14 : 20, fontWeight: '500' }}>{item.token}</Typography>


                                    <span
                                        style={{ color: "#858E9D", fontSize: phone ? 10 : 14, fontWeight: 500, textAlign: 'initial', float: 'right' }}
                                    >
                                        {t('Balance')}
                                        <span style={{ color: '#0173F9', marginLeft: 4, marginRight: 4 }}>{roundingDown(item.amount, 8)} </span>
                                        <span style={{ color: '#858E9D' }}> {item.token}</span>
                                    </span>
                                </div>
                                {
                                    coin === item.token ?
                                        <ListItemSecondaryAction style={{}}>
                                            <img style={{ height: phone ? 24 : 32, alignSelf: 'center' }} src={tickIcon} />
                                        </ListItemSecondaryAction> : null
                                }
                                {
                                    item.open ? null :
                                        <ListItemSecondaryAction>
                                            <Typography style={{ color: '#858E9D', fontSize: 14, fontStyle: 'oblique', fontWeight: 500 }}>{t('notAvailable')}</Typography>
                                        </ListItemSecondaryAction>
                                }
                            </ListItem>
                        )
                        )}
                    </List>
                </div>
                <div style={{ height: 40 }} />

            </div>
        </motion.div>
    )


    const cryptoWarningBody = (
        <motion.div
            initial={{ opacity: 0, scale: 0 }}
            animate={{ opacity: 1, scale: 1 }}
        >
            <div style={{
                backgroundColor: 'white', borderRadius: 20, width: phone ? " 89vw" : "606px", overflow: 'hidden', outline: 0, display: 'flex', alignItems: 'center', flexDirection: 'column'
            }}>
                <div style={{ width: '91%', marginTop: '7%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography style={{ fontSize: phone ? 16 : 24, fontWeight: "500", color: '#1C2237' }}>
                        {t('withdrawCrypoTitle')}
                    </Typography>

                </div>
                <div style={{ height: 1, width: '91%', backgroundColor: '#EFEFEF', marginTop: 12, marginBottom: 12 }} />

                <div style={{ justifyContent: 'space-between', display: 'flex', width: "91%" }}>

                </div>
                <div style={{ width: '91%', }}>
                    <Typography style={{ fontSize: phone ? 12 : 16, fontWeight: '500', fontFamily: "Avenir", color: 'black' }} >
                        {t("withdrawCrypoWarning1")}
                    </Typography>
                    <Typography style={{ fontSize: phone ? 12 : 16, fontWeight: '500', fontFamily: "Avenir", color: 'black' }} >
                        {t("withdrawCrypoWarning2")}
                    </Typography>

                </div>

                <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end', width: '100%' }}>
                    <Button
                        className={phone ? classes.buttonPhone : classes.button}
                        style={{ marginTop: phone ? 40 : 60, backgroundColor: '#0474F9', marginBottom: 24, marginRight: 24 }}
                        onClick={() => {
                            // setter
                            localStorage.setItem('showedCryptoWarning', false);
                            setShowCryptoWarning(false)
                        }}

                    >
                        <Typography className={classes.font} style={{ fontSize: phone ? 14 : 18, color: 'white', fontWeight: 500, marginLeft: 12, marginRight: 12, lineHeight: phone ? '11px' : '25px' }}>{t("withdrawCrypoConfirm")}</Typography>
                        <img style={{ height: phone ? 16 : 20 }} src={enterArrow}></img>
                    </Button>
                </div>
                {/* <div style={{ height: 40 }} /> */}

            </div>
        </motion.div>
    )

    const networkModalBody = (
        <motion.div
            initial={{ opacity: 0, scale: 0 }}
            animate={{ opacity: 1, scale: 1 }}
        >
            <div style={{
                backgroundColor: 'white', borderRadius: 20, width: phone ? " 89vw" : "606px", overflow: 'hidden', outline: 0, display: 'flex', alignItems: 'center', flexDirection: 'column'
            }}>
                <div style={{ width: '91%', marginTop: '7%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography style={{ fontSize: phone ? 16 : 24, fontWeight: "500", color: '#1C2237' }}>
                        {t('selectChain')}
                    </Typography>
                    <Button
                        // disabled={fundActionLoading || !loaded}
                        onClick={() => { handleClose() }}
                        style={{
                            display: 'inline-block',
                            padding: 0,
                            minHeight: 0,
                            minWidth: 0,
                        }}
                        variant="square"
                    >
                        <img src={closePopIcon} style={{ width: phone ? 32 : 40 }} />


                    </Button>
                </div>
                <div style={{ height: 1, width: '91%', backgroundColor: '#EFEFEF', marginTop: 12, marginBottom: 12 }} />

                <div style={{ justifyContent: 'space-between', display: 'flex', width: "91%" }}>
                    <List style={{ width: '100%' }} >
                        {chainList.map(item => (
                            <ListItem onClick={() => {
                                handleChainChange(item.chain)
                                setShowNetworkModal(false)
                            }} key={item.chain}
                                style={{ backgroundColor: chain === item.chain ? '#F9F9F9' : 'white', alignItems: 'center', marginBottom: 15, borderRadius: phone ? 12 : 20, paddingLeft: phone ? 8 : 20, paddingRight: phone ? 0 : 20, height: phone ? 28 : 60 }}
                            >

                                <div style={{ marginLeft: phone ? 0 : 8 }}>
                                    <Typography style={{ fontSize: phone ? 14 : 20, fontWeight: '500' }}>{item.chain === 'TRX' ? 'TRC20' : item.chain}</Typography>
                                </div>
                                {
                                    chain === item.chain ?
                                        <ListItemSecondaryAction>
                                            <img style={{ height: phone ? 24 : 32, alignSelf: 'center' }} src={tickIcon} />
                                        </ListItemSecondaryAction> : null
                                }
                            </ListItem>
                        )
                        )}
                    </List>
                </div>
                <div style={{ height: 40 }} />

            </div>
        </motion.div>
    )

    return (

        <div style={{ width: '100%', display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
            <Helmet>
                <title>{t("titleWithdraw")}</title>
                <meta name="keywords" content="Toronto, OTC, bitcoin, exchange, cryptocurrency, 多伦多, 场外交易平台, 比特币, 以太坊, 加元, 换汇，加密货币, 人民币,多倫多, 場外交易平台, 比特幣, 以太坊, 加元, 換匯, 人民幣, Yesbit, Fiat, CAD, BTC, ETH, USD, balance, withdraw, 提币" />
            </Helmet>
            <div style={{ width: phone ? '92%' : '60%', alignItems: 'center', textAlign: 'center', maxWidth: 543, minWidth: 317 }}>
                {loaded && selectingWithdrawType ? renderSelectWithdrawType() : null}
                {loaded && withdrawingCrypto ? renderWithdrawCrypto() : null}
                {loaded && confirmingCrypto ? renderConfirmingCrypto() : null}
                {loaded && inputting ? renderInputting() : null}
                {loaded && confirming ? renderConfirming() : null}
                {loaded && submitted ? renderSubmitted() : null}


            </div>
            <Modal
                open={showCryptoModal}

                onClose={() => {
                    setShowCryptoModal(false)
                }}
                style={{ alignItems: "center", display: 'flex', justifyContent: 'center' }}
            >
                {cryptoModalBody}
            </Modal>
            <Modal
                open={showNetworkModal}
                onClose={() => {
                    setShowNetworkModal(false)
                }}
                style={{ alignItems: "center", display: 'flex', justifyContent: 'center' }}
            >
                {networkModalBody}
            </Modal>


            {
                !localStorage.getItem('showedCryptoWarning') ?

                    <Modal
                        open={showCryptoWarning}
                        style={{ alignItems: "center", display: 'flex', justifyContent: 'center' }}
                    >
                        {cryptoWarningBody}
                    </Modal>
                    :
                    null
            }










            <ResultModal
                onCancel={() => {
                    setShowWithdrawFailedModal(false)
                    dispatch(fundsActions.clearWithdraw())
                    backToWithdrawCrypto()
                    setEmailCode('')
                    setVerificationCode('')
                }}
                modalVisible={showWithdrawFailedModal}
                t
                width={width}
                phone={phone}
                title={t('withdrawFailed')}
                content={t(localCryptoWithdrawMsg)}
                resultSuccess={false}
                handleClose={() => {
                    setShowWithdrawFailedModal(false)
                    dispatch(fundsActions.clearWithdraw())
                    backToWithdrawCrypto()
                }}
            />
        </div>
    )
}
export default withTranslation()(Withdraw)
