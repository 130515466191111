import {
    GET_USER_CAPITALS,GET_USER_CAPITALS_SUCCEED, GET_USER_CAPITALS_FAILED,
    GET_FUNDS_TRANSACTIONS, GET_FUNDS_FIAT_TRANSACTIONS_SUCCEED, GET_FUNDS_CRYPTO_TRANSACTIONS_SUCCEED, GET_FUNDS_TRANSACTIONS_FAILED,
    FIAT_DEPOSIT,FIAT_DEPOSIT_SUCCEED, FIAT_DEPOSIT_FAILED, CLEAR_DEPOSIT,
    PRICE_QUOTE, PRICE_QUOTE_SUCCEED, PRICE_QUOTE_FAILED, FIAT_WITHDRAW, FIAT_WITHDRAW_FAILED, FIAT_WITHDRAW_SUCCEED, CLEAR_WITHDRAW,
    CRYPTO_WITHDRAW, CRYPTO_WITHDRAW_FAILED, CRYPTO_WITHDRAW_SUCCEED, GET_ADDRESS, GET_ADDRESS_FAILED, GET_ADDRESS_SUCCEED,
    GET_TOKEN_INFO, GET_TOKEN_INFO_FAILED, GET_TOKEN_INFO_SUCCEED, GET_FEE, GET_FEE_FAILED, GET_FEE_SUCCEED, GET_BTC_QUOTE, GET_BTC_QUOTE_FAILED, GET_BTC_QUOTE_SUCCEED,
    GET_ETH_QUOTE, GET_ETH_QUOTE_FAILED, GET_ETH_QUOTE_SUCCEED
} from '../constants';

export function funds (state = {
    fundActionLoading: true,
    userCapitals: [],
    fundsCryptoTransactions: {
        list:[
            {
                cryptoType: '',
                cryptoAmount: 0,
                txsHash: '0x9bbf14a22951da568767e909443605dec93775d7cc20dd8e5361dad2ac6a645b'
            }
        ],
        page:1,
        size:10,
        isEnd:true,
        total:0
    },
    fundsFiatTransactions: {
        list:[
            {
                txsHash: '0x9bbf14a22951da568767e909443605dec93775d7cc20dd8e5361dad2ac6a645b'
            }
        ],
        page:1,
        size:10,
        isEnd:true,
        total:0
    },
    depositMsg: '',
    withdrawMsg: '',
    cryptoWithdrawMsg: '',
    priceQuote: {price: 0},
    fundMsg: '',
    tradeSubmitted: false,
    validDate: { moneyReqExpDate: '', redirectUrl: '' },
    tokensInfo: [{
        token: '',
        chain: '',
        depositIsOn: false
    }],
    feeInfo: {amount: 0},
    cryptoAddress: {address: ''},
    gettingAddress: false,
    getAddressMsg: '',
    getFeeMsg: '',
    getTokenInfoMsg: '',
    cryptoWithdrawSubmitted: false,
    ethQuote: {price: 0},
    btcQuote: {price: 0}
}, action) {
    switch (action.type) {
        case GET_USER_CAPITALS:
            return {
                ...state,
                fundActionLoading: true,
            }
        case GET_USER_CAPITALS_SUCCEED:
            return {
                ...state,
                userCapitals: action.data,
                fundActionLoading: false
            }
        case GET_USER_CAPITALS_FAILED:
            return {
                ...state,
                fundMsg: action.message,
                fundActionLoading: false
            }
        case GET_FUNDS_TRANSACTIONS:
            return {
                ...state,
                fundActionLoading: true
            }
        case GET_FUNDS_FIAT_TRANSACTIONS_SUCCEED:
            return {
                ...state,
                fundsFiatTransactions: action.data === undefined ?
                    {
                        list:[],
                        page:1,
                        size:10,
                        isEnd:true,
                        total:0
                    }
                    : action.data,
                fundActionLoading: false
            }
        case GET_FUNDS_CRYPTO_TRANSACTIONS_SUCCEED:
            return {
                ...state,
                fundsCryptoTransactions: action.data === undefined ?
                    {
                        list:[],
                        page:1,
                        size:10,
                        isEnd:true,
                        total:0
                    } : action.data,
                fundActionLoading: false
            }
        case GET_FUNDS_TRANSACTIONS_FAILED:
            return {
                ...state,
                fundMsg: action.message,
                fundActionLoading: false
            }
        case FIAT_DEPOSIT:
            return {
                ...state,
                tradeSubmitted: false,
                fundActionLoading: true,
                depositMsg: ''
            }
        case FIAT_DEPOSIT_SUCCEED:
            return {
                ...state,
                depositMsg: action.message,
                validDate: action.data,
                tradeSubmitted: true,
                fundActionLoading: false
            }
        case FIAT_DEPOSIT_FAILED:
            return {
                ...state,
                depositMsg: action.message,
                tradeSubmitted: false,
                fundActionLoading: false
            }
        case CLEAR_DEPOSIT:
            return {
                ...state,
                fundActionLoading: false,
                depositMsg: '',
                priceQuote: {cryptoAmount: 0},
                fundMsg: '',
                tradeSubmitted: false,
                validDate: { moneyReqExpDate: '' }
            }
        case PRICE_QUOTE:
            return {
                ...state,
                fundActionLoading: true
            }
        case PRICE_QUOTE_SUCCEED:
            return {
                ...state,
                priceQuote: action.data.payload,
                fundActionLoading: false
            }
        case PRICE_QUOTE_FAILED:
            return {
                ...state,
                fundMsg: action.message,
                fundActionLoading: false
            }
        case FIAT_WITHDRAW:
            return {
                ...state,
                tradeSubmitted: false,
                fundActionLoading: true,
                withdrawMsg: ''
            }
        case FIAT_WITHDRAW_SUCCEED:
            return {
                ...state,
                withdrawMsg: action.data,
                validDate: action.data,
                tradeSubmitted: true,
                fundActionLoading: false,
            }
        case FIAT_WITHDRAW_FAILED:
            return {
                ...state,
                withdrawMsg: action.message,
                tradeSubmitted: false,
                fundActionLoading: false,
            }
        case CLEAR_WITHDRAW:
            return {
                ...state,
                fundActionLoading: false,
                withdrawMsg: '',
                tradeSubmitted: false,
                validDate: { moneyReqExpDate: '' },
                cryptoWithdrawMsg: '',
                cryptoWithdrawSubmitted: false
            }
        case CRYPTO_WITHDRAW:
            return {
                ...state,
                cryptoWithdrawSubmitted: false,
                fundActionLoading: true,
                cryptoWithdrawMsg: ''
            }
        case CRYPTO_WITHDRAW_SUCCEED:
            return {
                ...state,
                cryptoWithdrawMsg: '',
                cryptoWithdrawSubmitted: true,
                fundActionLoading: false,
            }
        case CRYPTO_WITHDRAW_FAILED:
            return {
                ...state,
                cryptoWithdrawMsg: action.message,
                cryptoWithdrawSubmitted: false,
                fundActionLoading: false,
            }
        case GET_ADDRESS:
            return {
                ...state,
                gettingAddress: true
            }
        case GET_ADDRESS_SUCCEED:
            return {
                ...state,
                cryptoAddress: action.data,
                gettingAddress: false,
                fundActionLoading: false
            }
        case GET_ADDRESS_FAILED:
            return {
                ...state,
                getAddressMsg: action.data,
                gettingAddress: false,
                fundActionLoading: false,
                cryptoAddress: {
                    address: ''
                }
            }
        case GET_TOKEN_INFO:
            return {
                ...state,
            }
        case GET_TOKEN_INFO_SUCCEED:
            return {
                ...state,
                tokensInfo: action.data,
                fundActionLoading: false
            }
        case GET_TOKEN_INFO_FAILED:
            return {
                ...state,
                getTokenInfoMsg: action.data,
                fundActionLoading: false
            }
        case GET_FEE:
            return {
                ...state
            }
        case GET_FEE_SUCCEED:
            return {
                ...state,
                feeInfo: action.data
            }
        case GET_FEE_FAILED:
            return {
                ...state,
                getFeeMsg: action.data
            }
        case GET_BTC_QUOTE:
            return {
                ...state,
                fundActionLoading: true
            }
        case GET_BTC_QUOTE_SUCCEED:
            return {
                ...state,
                btcQuote: action.data.payload,
                fundActionLoading: false
            }
        case GET_BTC_QUOTE_FAILED:
            return {
                ...state,
                fundMsg: action.message,
                fundActionLoading: false
            }
        case GET_ETH_QUOTE:
            return {
                ...state,
                fundActionLoading: true
            }
        case GET_ETH_QUOTE_SUCCEED:
            return {
                ...state,
                ethQuote: action.data.payload,
                fundActionLoading: false
            }
        case GET_ETH_QUOTE_FAILED:
            return {
                ...state,
                fundMsg: action.message,
                fundActionLoading: false
            }
        default:
            return state;
    }
};
